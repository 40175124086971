import { Constants } from "@/Constants";
import { MeshHelper } from "@/helpers/MeshHelper";
import Project from "@/models/Project";
import Logger from "@/shared/logger";
import { Arcade, EditorState, PointType } from "@winnove/vue-wlib/enums";
import saveAs from "file-saver";
import {
  BufferAttribute,
  BufferGeometry,
  Color,
  Group,
  Matrix4,
  Scene as Scene3D,
  Vector3,
} from "three";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import { CtrlZAction } from "./SceneCommon";
import SceneControls from "./SceneControls";
import SceneJawArchWire from "./SceneJawArchWire";
import SceneMesh from "./SceneMesh";

export default class SceneJawArch {
  private _arcade: Arcade;
  public scan: SceneMesh;
  public wire: SceneJawArchWire;
  private _project: Project | null;
  private _controls: SceneControls;
  private _occlusion: number[] = [];

  constructor(
    arcade: Arcade,
    scene: Scene3D,
    project: Project | null,
    controls: SceneControls
  ) {
    this._arcade = arcade;
    this.scan = new SceneMesh(scene, controls);
    this.wire = new SceneJawArchWire(scene, arcade, project, controls);
    this._project = project;
    this._controls = controls;
  }

  public updateProject(p_project: Project) {
    this._project = p_project;
    this.wire.updateProject(p_project);
  }

  public disposeAll(): void {
    this.scan.disposeAll();
    this.wire.disposeAll();
    this._occlusion.length = 0;
  }

  public disposeAllButScan(): void {
    this.wire.disposeAll();
  }

  public loadFile(p_data: ArrayBuffer): boolean {
    console.log("SceneJawArch.loadFile");
    const arch = this._project?.getArch(this._arcade);
    if (!arch) {
      Logger.getInstance().error(
        "SceneJawArch.loadFile: arch is null",
        "Impossible de charger le fichier, veuillez réessayer."
      );
      return false;
    }
    this.disposeAll();
    return this.scan.loadFile(p_data, arch);
  }

  public rotate(p_axis: Vector3, p_angle: number): void {
    this.scan.rotate(p_axis, p_angle);
    this.wire.rotate(p_axis, p_angle);
  }

  public setPlaneLineVisible(p_visible: boolean): void {
    if (p_visible) {
      const plane = this.wire.plane.getPlane();
      if (plane)
        this.scan.planeIntersection.plane.value.set(
          plane.normal.x,
          plane.normal.y,
          plane.normal.z,
          -plane.constant
        );
      else this.scan.planeIntersection.plane.value.set(0, 0, 0, 0);
    } else {
      this.scan.planeIntersection.plane.value.set(0, 0, 0, 0);
    }
  }

  public updateScene(
    p_editorState: EditorState,
    p_selectedArcade: Arcade
  ): void {
    if (this._arcade === p_selectedArcade) {
      // if the jaw arch is selected
      switch (p_editorState) {
        case EditorState.PLANE_DRAWING:
          this.setPlanePointsVisible(true);
          this.setWirePointsVisible(false);
          this.setPlaneVisible(true);
          this.wire.setWireMeshVisible(false);
          this.setPlaneLineVisible(true);
          break;
        case EditorState.WIRE_DRAWING:
          this.setPlanePointsVisible(false);
          this.setWirePointsVisible(true);
          this.setPlaneVisible(true);
          this.wire.setWireMeshVisible(false);
          this.setPlaneLineVisible(true);
          break;
        case EditorState.WIRE_SUMMARY:
          this.updateWireMesh();
          this.setPlanePointsVisible(false);
          this.setWirePointsVisible(false);
          this.setPlaneVisible(false);
          this.setPlaneLineVisible(true);
          break;
        case EditorState.WIRE_MANUAL_EDITING:
          this.updateWireMesh();
          this.setPlanePointsVisible(false);
          this.setWirePointsVisible(true);
          this.setPlaneVisible(false);
          this.setPlaneLineVisible(true);
          break;
        default:
          this.updateWireMesh();
          this.setPlanePointsVisible(false);
          this.setWirePointsVisible(false);
          this.setPlaneVisible(false);
          this.setPlaneLineVisible(false);
          break;
      }
    } else {
      // if the jaw arch is not selected
      this.updateWireMesh();
      this.setPlanePointsVisible(false);
      this.setWirePointsVisible(false);
      this.setPlaneVisible(false);
      this.setPlaneLineVisible(false);
    }
    this.updateZonePoints();
    this.updatePenetration();
  }

  public toggleKeyMode() {
    this.wire.toggleKeyMode();
    this.updateWireMesh();
    this.updateZonePoints();
    this.updatePenetration();
  }

  public setVisible(
    p_visible: boolean,
    p_editorState: EditorState,
    p_selectedArcade: Arcade
  ): void {
    this.scan.setMeshVisible(p_visible);
    this.updateScene(p_editorState, p_selectedArcade);
  }

  public loadPoints(p_type: PointType) {
    if (this._project?.getPrescription(this._arcade))
      this.wire.loadPoints(p_type);
  }

  public addPoint(
    p_point: Vector3,
    p_normal: Vector3,
    p_type: PointType,
    p_id: number,
    p_camPos: Vector3
  ): CtrlZAction | undefined {
    if (this.scan.mesh) {
      // check if the order is not cancelled
      if (this._project?.getPrescription(this._arcade)?.order?.isCancelled()) {
        Logger.getInstance().warning(
          "Impossible d'ajouter un point sur cette arcade car la prescription est annulée"
        );
        return;
      }
      const name = this.wire.addPoint(
        p_point,
        p_normal,
        p_type,
        p_id,
        this.scan.mesh,
        p_camPos
      );
      this.setPlaneLineVisible(true);
      this.updateZonePoints();
      return name;
    } else
      Logger.getInstance().warning(
        "Impossible d'ajouter un point sur cette arcade car il n'y a pas de scan"
      );
  }

  public exportWireMesh() {
    // check if the wire mesh exists
    if (!this.wire.wireMesh.mesh || !this.scan.mesh) {
      Logger.getInstance().warning(
        "Impossible d'exporter ce fil en STL. Vérifiez que le fil existe bien et réessayez."
      );
      return;
    }
    this.wire.wireMesh.saveMeshAsSTL(this._getPrefix() + "_wire.stl");
    this.scan.saveMeshAsSTL(this._getPrefix() + "_arch.stl");
  }

  public exportWireMeshAndJawTogether() {
    // check if the wire mesh exists
    if (!this.wire.wireMesh.mesh || !this.scan.mesh) {
      Logger.getInstance().warning(
        "Impossible d'exporter ce fil en STL. Vérifiez que le fil existe bien et réessayez."
      );
      return;
    }
    const group = new Group();
    group.add(this.wire.wireMesh.mesh.clone());
    group.add(this.scan.mesh.clone());
    this._saveMeshAsSTL(group, this._getPrefix() + "_wire_arch.stl");
  }

  private _getPrefix(): string {
    // export the wire mesh
    let filePrefix = this._arcade === Arcade.MANDIBLE ? "L" : "U";
    filePrefix += this._project ? this._project.reference : "";
    return filePrefix;
  }

  private _saveMeshAsSTL(p_mesh: Group, p_name: string): void {
    const exporter = new STLExporter();
    const options = { binary: true };
    const buffer = exporter.parse(p_mesh, options);
    const blob = new Blob([buffer]);
    saveAs(blob, p_name);
  }

  public getScanMatrix(): Matrix4 | null {
    if (this.scan.mesh) {
      const matrix = this.scan.mesh.matrixWorld.clone();
      return matrix;
    } else {
      return null;
    }
  }

  // reset the scan matrix to the original one
  public resetScanMatrix(): void {
    if (this.scan.mesh) {
      // reset the scan matrix
      const matrix = this.getScanMatrix();
      if (matrix) {
        matrix.invert();
        this.applyMatrix4(matrix);
      }
      // apply the original matrix
      const arch = this._project?.getArch(this._arcade);
      if (!arch) {
        Logger.getInstance().error(
          "SceneJawArch.resetScanMatrix: arch is null",
          "Impossible de remettre à zéro la position, veuillez réessayer."
        );
        return;
      }
      MeshHelper.prepareJawMesh(
        this.scan.mesh,
        arch.modificationDate,
        arch.matrix
      );
    }
  }

  public getPoints(p_type: PointType): string {
    return this.wire.getPoints(p_type);
  }

  public getPointsNb(p_type: PointType): number {
    return this.wire.getPointsNb(p_type);
  }

  public updateWireMesh() {
    this.wire.updateWireMesh(this.scan.isVisible());
    this.updateZonePoints();
    this.updatePenetration();
  }

  // update the zone point positions and visibility
  public updateZonePoints() {
    this.wire.updateZonePoints(
      this.scan.isVisible() && this._controls.showZones,
      this.scan.mesh!
    );
  }

  // update the penetration visibility
  public updatePenetration() {
    this.wire.updatePenetration(
      this.scan.isVisible() && this._controls.showPenetration,
      this.scan.mesh!
    );
  }

  public setPlanePointsVisible(p_visible: boolean) {
    this.wire.setPlanePointsVisible(p_visible && this.scan.isVisible());
  }

  public setPlaneVisible(p_visible: boolean) {
    this.wire.setPlaneMeshVisible(p_visible && this.scan.isVisible());
  }

  public setWirePointsVisible(p_visible: boolean) {
    this.wire.setWirePointsVisible(p_visible && this.scan.isVisible());
  }

  public showOcclusion(p_show: boolean, p_occlusion: string | null) {
    // First time.
    if (p_occlusion) {
      this._occlusion = JSON.parse(p_occlusion);
    }

    // If the occlusion is empty, we can't show it.
    if (p_show && this._occlusion.length === 0) {
      Logger.getInstance().error(
        "Impossible d'afficher l'occlusion. Veuillez réessayer et nous contacter si l'erreur persiste."
      );
      return;
    }

    // ensure the scan mesh is visible when we want to show the occlusion
    if (p_show) this.scan.setMeshVisible(true);

    const geometry: BufferGeometry = this.scan.mesh!.geometry;

    const positions = geometry.getAttribute("position");
    const vertexCount: number = positions.count;

    const colors = new Float32Array(vertexCount * 3);
    colors.fill(1);

    if (p_show) {
      for (let i = 1; i < this._occlusion.length; i += 2) {
        const faceId = this._occlusion[i];
        const distance = this._occlusion[i + 1];

        // 3 vertices per face, and 3 components per vertice.
        const idFace = faceId * 3 * 3;

        // Compute color.

        let gradient: number =
          (distance - Constants.OCCLUSION_MIN_DISTANCE) /
          (Constants.OCCLUSION_MAX_DISTANCE - Constants.OCCLUSION_MIN_DISTANCE);
        gradient = Math.min(Math.max(gradient, 0), 1);

        const color = new Color(1, gradient, 0);

        // Apply to buffer.
        colors[idFace + 0 + 0] = color.r;
        colors[idFace + 0 + 1] = color.g;
        colors[idFace + 0 + 2] = color.b;
        colors[idFace + 3 + 0] = color.r;
        colors[idFace + 3 + 1] = color.g;
        colors[idFace + 3 + 2] = color.b;
        colors[idFace + 6 + 0] = color.r;
        colors[idFace + 6 + 1] = color.g;
        colors[idFace + 6 + 2] = color.b;
      }
    }

    geometry.setAttribute("color", new BufferAttribute(colors, 3));
  }

  public isOcclusionComputed(): boolean {
    return this._occlusion.length > 0;
  }

  public snapOnPlane(): boolean {
    let ret: boolean = true;
    if (this.scan.mesh) ret = this.wire.snapOnPlane(this.scan.mesh);
    if (!ret) {
      Logger.getInstance().warning(
        "Des points du " +
          (this._arcade === Arcade.MAXILLA ? "maxillaire" : "mandibulaire") +
          " n'ont pas pu être mis sur votre plan. Veuillez changer le plan ou revenir à l'étape précédente."
      );
    }
    return ret;
  }

  public changePosition(p_id: number, p_position: Vector3) {
    this.wire.changePosition(p_id, p_position);
  }

  public getPointPosition(p_id: number): Vector3 {
    return this.wire.getPointPosition(p_id);
  }

  public getPointMeshPosition(p_id: number): Vector3 {
    return this.wire.getPointMeshPosition(p_id);
  }

  public applyMatrix4(p_matrix: Matrix4) {
    this.scan.applyMatrix4(p_matrix);
  }
}

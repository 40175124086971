<template>
  <tr
    :class="
      p_isSelected ? 'AdminProjectRow bg-primary text-white' : 'AdminProjectRow'
    "
  >
    <!-- Comments. -->
    <td>
      <div v-if="orderMaxilla">
        <w-badge
          v-if="orderMaxilla.hasAttestationComment()"
          color="red"
          overlap
          dot
          bordered
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
        <w-badge
          v-else-if="orderMaxilla.hasInternalComment()"
          color="green"
          overlap
          dot
          bordered
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
        <w-badge
          v-else-if="orderMaxilla.hasClientComment()"
          color="blue"
          overlap
          dot
          bordered
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
        <w-badge
          v-else
          color="white"
          overlap
          dot
          bordered
          style="visibility: hidden"
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
      </div>
      <div v-if="orderMandible">
        <w-badge
          v-if="orderMandible.hasAttestationComment()"
          color="red"
          overlap
          dot
          bordered
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
        <w-badge
          v-else-if="orderMandible.hasInternalComment()"
          color="green"
          overlap
          dot
          bordered
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
        <w-badge
          v-else-if="orderMandible.hasClientComment()"
          color="blue"
          overlap
          dot
          bordered
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
        <w-badge
          v-else
          color="white"
          overlap
          dot
          bordered
          style="visibility: hidden"
          ><w-icon :icon="mdiMessageTextOutline"></w-icon
        ></w-badge>
      </div>
    </td>

    <!-- Installation date. -->
    <td>
      <p class="ma-0">
        {{ p_project.getFormatedInstallationDate() }}
      </p>
    </td>

    <!-- State. -->
    <td>
      <ProjectStateCell
        :p_project="p_project"
        :p_isSelected="p_isSelected"
        :p_privilege="p_privilege"
      />
    </td>

    <!-- Reference. -->
    <td>
      <p
        v-if="orderMaxilla"
        class="ma-0"
      >
        {{ orderMaxilla.getReference() }}
      </p>
      <p
        v-if="orderMandible"
        class="ma-0"
      >
        {{ orderMandible.getReference() }}
      </p>
    </td>

    <!-- Doctor. -->
    <td align-self="center">
      <p class="ma-0">Dr. {{ p_project.getDoctorName() }}</p>
    </td>

    <!-- Patient. -->
    <td
      align-self="center"
      :class="p_isSelected ? 'white--text' : 'black--text'"
    >
      <p class="ma-0">
        {{ p_project.getPatientLastName() }}
      </p>
      <p class="ma-0">
        {{ p_project.getPatientFirstName() }}
      </p>
    </td>

    <!-- Arcade image. -->
    <td>
      <span class="d-flex">
        <img
          :style="p_isSelected ? 'display: block;' : 'display: none;'"
          :src="
            orderMaxilla && orderMandible
              ? '/img/icon/default/maxilla-mandible.svg'
              : orderMaxilla
                ? '/img/icon/default/maxilla.svg'
                : '/img/icon/default/mandible.svg'
          "
          alt="Arcades maxillaire et mandibulaires"
        />
        <img
          :style="p_isSelected ? 'display: none;' : 'display: block;'"
          :src="
            orderMaxilla && orderMandible
              ? '/img/icon/active/maxilla-mandible.svg'
              : orderMaxilla
                ? '/img/icon/active/maxilla.svg'
                : '/img/icon/active/mandible.svg'
          "
          alt="Arcades maxillaire et mandibulaires"
        />
      </span>
    </td>

    <!-- Key. -->
    <td>
      <div v-if="orderMaxilla && !orderMaxilla.toBeCompleted()">
        <span v-if="wireMaxilla && wireMaxilla.keyNeeded">oui</span>
        <span v-else>non</span>
      </div>
      <div v-if="orderMandible && !orderMandible.toBeCompleted()">
        <span v-if="wireMandible && wireMandible.keyNeeded">oui</span>
        <span v-else>non</span>
      </div>
    </td>

    <!-- I3D. -->
    <td>
      <div v-if="orderMaxilla && !orderMaxilla.toBeCompleted()">
        <w-checkbox
          v-model="orderMaxilla.printed3D"
          @input="updateOrder(orderMaxilla)"
          v-ripple
          hide-details
          :color="p_isSelected ? 'white' : 'gray'"
          density="compact"
        ></w-checkbox>
      </div>
      <div v-if="orderMandible && !orderMandible.toBeCompleted()">
        <w-checkbox
          v-model="orderMandible.printed3D"
          @input="updateOrder(orderMandible)"
          v-ripple
          hide-details
          :color="p_isSelected ? 'white' : 'gray'"
          density="compact"
        ></w-checkbox>
      </div>
    </td>

    <!-- Teeth range. -->
    <td>
      <span v-if="wireMaxilla && orderMaxilla && !orderMaxilla.toBeCompleted()">
        {{ wireMaxilla.teethRange }}
      </span>
      <br v-if="orderMaxilla && orderMandible" />
      <span
        v-if="wireMandible && orderMandible && !orderMandible.toBeCompleted()"
      >
        {{ wireMandible.teethRange }}
      </span>
    </td>

    <!-- Creation date. -->
    <td align-self="center">
      <div class="row">
        <p class="">
          {{ p_project.getFormatedCreationDate() }}
        </p>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import OrderHelper from "@/helpers/OrderHelper";
import Order from "@/models/Order";
import Project from "@/models/Project";
import Wire from "@/models/Wire";
import { Privilege } from "@winnove/vue-wlib/enums";
import { mdiContentDuplicate, mdiMessageTextOutline } from "@mdi/js";
import { ComputedRef, computed, defineComponent, ref } from "vue";
import ProjectStateCell from "./cells/ProjectStateCell.vue";

export default defineComponent({
  name: "AdminProjectRow",
  components: {
    ProjectStateCell,
  },

  props: {
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_isSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const orderMandible: ComputedRef<Order | null> = computed(() => {
      return props.p_project.orderMandible();
    });

    const dupplicationDialog = ref<InstanceType<typeof BaseDialog> | null>(
      null
    );

    const closeDialog = () => {
      dupplicationDialog.value?.close();
    };

    const orderMaxilla: ComputedRef<Order | null> = computed(() => {
      return props.p_project.orderMaxilla();
    });

    const wireMandible: ComputedRef<Wire | null> = computed(() => {
      return props.p_project.wireMandible();
    });

    const wireMaxilla: ComputedRef<Wire | null> = computed(() => {
      return props.p_project.wireMaxilla();
    });

    function trackOrder(p_order: Order | null) {
      if (!p_order) return;
      return OrderHelper.trackOrder(p_order.trackingNumber);
    }

    async function updateOrder(p_order: Order | null) {
      if (!p_order) return;
      await OrderHelper.updateOrder(p_order);
      emit("updateOrder", p_order);
    }

    return {
      orderMandible,
      orderMaxilla,
      wireMandible,
      wireMaxilla,
      dupplicationDialog,
      closeDialog,
      updateOrder,
      trackOrder,
      mdiContentDuplicate,
      mdiMessageTextOutline,
    };
  },
});
</script>

<style lang="scss" scoped>
.AdminProjectRow {
  position: relative;

  .duplicate {
    position: absolute;
    bottom: 0;
    right: -10px;
    opacity: 0.3;
    &:hover {
      opacity: 0.8;
    }
  }
}

.AdminProjectRow :deep(.v-checkbox .v-selection-control) {
  min-height: 0px !important;
}
</style>

<template>
  <w-menu
    :modelValue="p_visible"
    activator="parent"
  >
    <w-card class="pa-2 mt-2">
      <w-card-title class="pa-2 pt-0">Dernières modifications</w-card-title>

      <w-btn
        variant="plain"
        color="black"
        :icon="mdiClose"
        @click="$emit('close-history')"
        size="x-large"
        width="27px"
        height="27px"
        style="color: black; position: absolute; right: 0; top: 0; margin: 10px"
      >
      </w-btn>

      <w-list
        max-height="200px"
        class="bg-white"
      >
        <w-list-item
          v-for="(wireHistory, index) in p_wireHistory"
          :key="index"
          @click="
            m_selected === index
              ? unloadWire(wireHistory)
              : loadWire(index, wireHistory)
          "
          color="wOrange"
          base-color="primary"
          :active="m_selected === index"
          :disabled="index === 0"
          link
          :title="generateText(wireHistory)"
        >
        </w-list-item>
      </w-list>
      <w-btn
        @click="restoreWire(p_wireHistory[m_selected])"
        :disabled="m_selected === -1"
        block
        color="white"
        class="mt-2"
        >Revenir sur la version sélectionnée</w-btn
      >
    </w-card>
  </w-menu>
</template>

<script lang="ts">
import WireHistory from "@/models/WireHistory";
import { AlgoVersion } from "@winnove/vue-wlib/enums";
import { mdiClose } from "@mdi/js";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "WireHistoryDrawer",
  props: {
    p_wireHistory: {
      type: Array as () => WireHistory[],
      required: false,
    },
    p_visible: {
      type: Boolean as () => boolean,
      required: false,
    },
  },
  setup(props, context) {
    const m_selected = ref(-1);

    function getWireToLoad(wireHistory: WireHistory) {
      return JSON.stringify([
        {
          dimension: wireHistory.wire?.dimension,
          isSquared: wireHistory.wire?.wireType === 1,
          material: wireHistory.wire?.material,
          points: JSON.parse(wireHistory.wirePoints),
          history: true,
          historic: true,
        },
      ]);
    }

    function generateText(wireHistory: WireHistory) {
      let textToDisplay = `Dr ${wireHistory.user?.lastName.toUpperCase()}`;
      switch (wireHistory.algoVersion) {
        case AlgoVersion.RESTORE:
          textToDisplay += ` est revenu sur un ancienne version du fil le`;
          break;
        case AlgoVersion.MANUAL:
          textToDisplay += ` a modifié le fil le`;
          break;
        case AlgoVersion.AI:
          textToDisplay += ` a utilisé l'IA pour générer le fil le`;
          break;
        case AlgoVersion.AI_CHOICE_1:
        case AlgoVersion.AI_CHOICE_2:
        case AlgoVersion.AI_CHOICE_3:
          textToDisplay += ` a utilisé l'IA (choix ${
            wireHistory.algoVersion - 10
          }) pour générer le fil le`;
          break;
        default:
          textToDisplay += ` a assemblé le fil (algo ${wireHistory.algoVersion}) le`;
          break;
      }
      textToDisplay += ` ${new Date(
        wireHistory.timestamp
      ).toLocaleDateString()} à ${new Date(
        wireHistory.timestamp
      ).toLocaleTimeString()} avec ${
        JSON.parse(wireHistory.wirePoints).length
      } points`;
      return textToDisplay;
    }

    function restoreWire(wireHistory: WireHistory) {
      context.emit("restore-wire", JSON.stringify(wireHistory));
      m_selected.value = -1;
      context.emit("close-history");
    }

    function loadWire(index: number, wireHistory: WireHistory) {
      m_selected.value = index;
      context.emit("load-wire", getWireToLoad(wireHistory));
    }

    function unloadWire(wireHistory: WireHistory) {
      context.emit("unload-wire", JSON.stringify(wireHistory));
      m_selected.value = -1;
    }

    watch(
      () => props.p_visible,
      () => {
        if (props.p_visible === false) {
          m_selected.value = -1;
          context.emit("unload-wire");
        }
      }
    );
    watch(
      () => props.p_wireHistory,
      () => {
        m_selected.value = -1;
      }
    );

    return {
      m_selected,
      getWireToLoad,
      generateText,
      restoreWire,
      loadWire,
      unloadWire,
      mdiClose,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  top: 120% !important;
}
.v-list {
  height: 75%;
  overflow-y: auto;
}

.custom-btn {
  width: 100%;
  white-space: normal;
  text-align: center;
  word-break: break-word;
}
:v-deep(.v-btn:not(.action-btn).v-size--default) {
  height: auto;
}
.custom-btn:v-deep(.v-btn__content) {
  flex: unset;
}
</style>

<template>
  <div class="pa-0 scene-container">
    <!-- The scene itself -->
    <div
      id="scene"
      ref="scene"
      class="scene-container"
      @contextmenu.prevent="raycast($event)"
    ></div>
    <div
      v-if="m_showOverlay"
      id="sceneOverlay"
    >
      <w-progress-circular
        indeterminate
        color="white"
      ></w-progress-circular>
    </div>
    <!-- The scene toolbar on the left -->
    <div id="sceneLeftToolbarDiv">
      <SceneToolbar
        ref="sceneLeftToolbar"
        id="sceneLeftToolbar"
        :p_privilege="p_privilege"
        :p_editorState="p_editorState"
        :p_project="p_project"
        :p_rotationToolActive="rotationToolActive"
        @load-file="$emit('load-file')"
        @rotate-lingual="rotateLingual()"
        @turn-maxilla="turnMaxilla()"
        @rotation-mode="toggleRotationTool(false)"
        @insert-point="insertPoint()"
        @remove-point="removePoint()"
        @toggle-gimbal="toggleGimbal"
        @toggle-distance-check="setDistanceCheck"
      />
    </div>
    <!-- The scene toolbar on the top -->
    <div id="sceneViewToolbarDiv">
      <SceneViewToolbar
        ref="sceneViewToolbar"
        id="sceneViewToolbar"
        :p_privilege="p_privilege"
        :p_editorState="p_editorState"
        :p_project="p_project"
        :p_rotationToolActive="rotationToolActive"
        :p_isCtrlZEmpty="isCtrlZEmpty"
        :p_isCtrlYEmpty="isCtrlYEmpty"
        :p_selectedArcade="p_selectedArcade"
        :p_wireHistory="m_wiresHistory"
        @view-lingual="viewLingual()"
        @view-occlusal="viewOcclusal()"
        @show-maxilla="showMaxilla"
        @show-mandible="showMandible"
        @toggle-opacity="toggleOpacity()"
        @show-contact-points="showContactPoints"
        @show-zones="showZones"
        @show-penetration="showPenetration"
        @ctrl-z="ctrlZ()"
        @ctrl-y="ctrlY()"
        @load-wire="loadWires"
        @unload-wire="unloadWires()"
        @restore-wire="$emit('restore-wire', $event)"
        @select-control="selectControl"
      />
    </div>
    <!-- The ai wire choice card on the right -->
    <div id="sceneViewAIWireChoiceDiv">
      <SceneViewAIWireChoice
        ref="sceneViewAIWireChoice"
        id="sceneViewAIWireChoice"
        :p_privilege="p_privilege"
        :p_editorState="p_editorState"
        :p_project="p_project"
        :p_selectedArcade="p_selectedArcade"
        :p_jsonWires="p_aiJsonWires"
        :p_snapshotArray="m_aiSnapshotArray"
        @buildWireInScene="loadWires"
        @unload-wire="unloadWires()"
        @getSnapshots="getAIsnapshots"
        @requestManuelDrawing="$emit('goBack')"
        @submitWireChoice="submitWireChoice"
      />
    </div>

    <!-- User action cards-->
    <!-- hide / show JSON wires -->
    <w-card
      v-if="p_privilege === Privilege.ADMIN && wiresInfos.length"
      class="tool-card"
    >
      <w-btn
        v-for="index in wiresInfos.length"
        :key="index"
        @click="toggleWire(index - 1, p_selectedArcade)"
        class="mr-2"
        variant="outlined"
        :color="wiresInfos[index - 1].color"
      >
        {{ wiresInfos[index - 1].isVisible ? "👀" : "😑" }}
      </w-btn>
    </w-card>

    <!-- Rotation tool confirmation card -->
    <w-card
      v-if="rotationToolActive"
      title="Outil de rotation"
      class="tool-card"
    >
      <w-btn
        @click="getAutomaticArchOrientation()"
        class="mt-2"
        block
        variant="tonal"
        color="white"
        :append-icon="mdiAutoFix"
      >
        <div>Orientation auto</div>
      </w-btn>
      <w-btn
        @click="
          toggleRotationTool(true);
          $emit('save');
        "
        class="mt-2"
        color="white"
        block
        :append-icon="mdiCheckboxMarkedCircle"
      >
        <div>Sauvegarder</div>
      </w-btn>
      <w-btn
        @click="toggleRotationTool(false)"
        color="white"
        class="mt-2"
        block
        variant="tonal"
      >
        <div>Annuler</div>
      </w-btn>
    </w-card>
  </div>
</template>

<script lang="ts">
import { Constants } from "@/Constants";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import Project from "@/models/Project";
import WireHistory from "@/models/WireHistory";
import {
  AlgoVersion,
  Arcade,
  CtrlZActionType,
  EditorState,
  GimbalIndex,
  GimbalType,
  PointType,
  Privilege,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { mdiAutoFix, mdiCheckboxMarkedCircle } from "@mdi/js";
import * as TWEEN from "@tweenjs/tween.js";
import mitt from "mitt";
import { useRepo } from "pinia-orm";
import {
  Box3,
  Box3Helper,
  Color,
  Euler,
  Intersection,
  Matrix4,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  Scene as Scene3D,
  Vector2,
  Vector3,
  WebGLRenderer,
} from "three";
import { Ref, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { LoaderBus } from "../main/Loader.vue";
import SceneToolbar from "./SceneToolbar.vue";
import SceneViewAIWireChoice from "./SceneViewAIWireChoice.vue";
import SceneViewToolbar from "./SceneViewToolbar.vue";
import {
  CtrlZAction,
  getCameraDefaultPositionFromBBox,
} from "./scene/SceneCommon";
import SceneControls from "./scene/SceneControls";
import SceneJaw from "./scene/SceneJaw";

export interface ArrayBufferEventData {
  name: string;
  data: ArrayBuffer;
}

export interface WireInfos {
  isVisible: boolean;
}

export type SceneEvent = {
  eventName: string; // simple events that don't need data
  eventData: ArrayBufferEventData; // events to transmit array buffer data
};

export const sceneEventBus = mitt<SceneEvent>();

export default defineComponent({
  name: "Scene",
  components: {
    SceneToolbar,
    SceneViewToolbar,
    SceneViewAIWireChoice,
  },
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_editorState: {
      type: Number as () => EditorState,
      required: true,
    },
    p_selectedArcade: {
      type: Number as () => Arcade,
      required: true,
    },
    p_aiJsonWires: {
      type: String,
      required: true,
    },
    p_query: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();

    // Scene and controls
    const scene = ref<HTMLElement | null>(null);
    let m_scene: Scene3D = new Scene3D();
    let m_controls: SceneControls = new SceneControls(m_scene);

    // Meshes.
    let m_jaw: SceneJaw = new SceneJaw(m_scene, props.p_project, m_controls);

    // Occlusion.
    let m_showOcclusion = false;

    // Tools.
    const rotationToolActive = ref(false);
    const sceneViewToolbar = ref<InstanceType<typeof SceneViewToolbar> | null>(
      null
    );
    const sceneLeftToolbar = ref<InstanceType<typeof SceneToolbar> | null>(
      null
    );
    const sceneViewAIWireChoice = ref<InstanceType<
      typeof SceneViewAIWireChoice
    > | null>(null);
    const isCtrlZEmpty = ref(true);
    const isCtrlYEmpty = ref(true);
    const wiresInfos: Ref<WireInfos[]> = ref([]);
    const m_wiresHistory = ref(
      props.p_project.getWiresHistory(props.p_selectedArcade)
    );

    const m_aiSnapshotArray: Ref<string[]> = ref([]);
    const m_showOverlay = ref(true);

    onMounted(() => {
      // Listen to events.
      sceneEventBus.on("eventName", (evt: string) => {
        switch (evt) {
          case "deleteMandi":
            if (m_scene) m_jaw.mandi.disposeAll();
            break;
          case "deleteMaxi":
            if (m_scene) m_jaw.maxi.disposeAll();
            break;
        }
      });

      sceneEventBus.on("eventData", (data: ArrayBufferEventData) => {
        switch (data.name) {
          case "loadMandi":
            if (m_scene) {
              props.p_project.archMandible()!.matrix = "";
              loadFile(data.data, Arcade.MANDIBLE);
            }
            break;
          case "loadMaxi":
            if (m_scene) {
              logger.debug(
                "loadMaxi : ",
                JSON.stringify(props.p_project.archMaxilla())
              );
              props.p_project.archMaxilla()!.matrix = "";
              loadFile(data.data, Arcade.MAXILLA);
            }
            break;
        }
      });

      document.documentElement.style.overflow = "hidden";
      addEventListener("resize", resizeScene);

      // Setup scene.
      m_controls.init();

      addEventListener("keyup", _onKeyUp);
      addEventListener("mouseup", _onMouseUp);
      addEventListener("keydown", _onKeyDown);
      m_controls.translateControls.forEach((p_control, p_id) => {
        if (p_id > GimbalIndex.BASE_PLANE_ANTERO) {
          p_control.attach(m_jaw.wireGimbalMeshes[p_id - 1]);
          p_control.showX = p_control.showY = p_control.showZ = false;
        }
        p_control.addEventListener("mouseUp", () => {
          _onGimbalRelease(true);
        });
      });
    });

    onUnmounted(() => {
      // Remove resize listener.
      document.documentElement.style.overflow = "auto";
      removeEventListener("resize", resizeScene);

      // Remove event listeners.
      removeEventListener("keyup", _onKeyUp);
      removeEventListener("mouseup", _onMouseUp);
      removeEventListener("keydown", _onKeyDown);

      // Dispose.
      m_jaw.disposeAll();

      // Dispose controls
      m_controls.dispose();
    });

    function _onKeyUp(e: KeyboardEvent): void {
      if (!(e.target as Element).closest("#sceneDiv")) return;

      if (props.p_editorState === EditorState.WIRE_MANUAL_EDITING) {
        // Detection du bouton Suppr ou de la flêche de retour pour supprimer un point sélectionné
        if (e.key === "Delete" || e.key === "Backspace") removePoint();
        // Detection du bouton Insert ou de la lettre i/I pour insérer un point entre le point sélectionné et le point suivant
        if (e.key === "Insert" || e.key === "i" || e.key === "I") insertPoint();
        // Detection de la lettre w/W pour toggle la gimbal base
        if (e.key === "w" || e.key === "W") toggleGimbal(GimbalType.BASE);
        // Detection de la lettre p/P pour toggle la gimbal plane
        if (e.key === "p" || e.key === "P") toggleGimbal(GimbalType.PLANE);
        // Detection de la lettre a/A pour toggle la gimbal antéro-postérieure
        if (e.key === "a" || e.key === "A")
          toggleGimbal(GimbalType.ANTERO_POSTERIOR);
        // Detection de la lettre t/T pour toggle la gimbal transversale
        if (e.key === "t" || e.key === "T")
          toggleGimbal(GimbalType.TRANSVERSAL);
        // Detection de la lettre v/V pour toggle la gimbal verticale
        if (e.key === "v" || e.key === "V") toggleGimbal(GimbalType.VERTICAL);
        // Detection de la flèche droite/haut pour avancer le point sur l'axe de gimbal
        if (e.key === "ArrowRight" || e.key === "ArrowUp")
          _onGimbalRelease(true);
        // Detection de la flèche gauche/bas pour reculer le point sur l'axe de gimbal
        if (e.key === "ArrowLeft" || e.key === "ArrowDown")
          _onGimbalRelease(true);
      }

      // Detection du bouton Shift pour afficher/cacher les zones autour de chaques points dans lesquelles on ne peux pas rajouter de point
      if (e.key === "Shift") {
        if (
          props.p_editorState === EditorState.WIRE_DRAWING ||
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING
        ) {
          // toggle showZones
          showZones(!m_controls.showZones);
        } else
          logger.warning(
            "Vous ne pouvez afficher les zones des points que lorsqu'ils sont visibles."
          );
      }

      // Detection de la lettre r/R pour afficher/cacher l'outil de rotation des meshs, celui-ci force la vue linguale
      if (e.key === "r" || e.key === "R") {
        if (props.p_editorState === EditorState.PRESCRIPTION_EDIT)
          toggleRotationTool(false);
        else
          logger.warning(
            "Le mode rotation est réservé à l'édition de la prescription."
          );
      }

      // Detection de la combinaison ctrl+z pour annuler une action de point
      if (e.ctrlKey && (e.key === "z" || e.key === "Z")) ctrlZ();

      // Detection de la combinaison ctrl+z pour refaire une action de point
      if (e.ctrlKey && (e.key === "y" || e.key === "Y")) ctrlY();

      // Detection du bouton Tab pour visualiser la pénétration
      if (e.key === "n" || e.key === "N") {
        if (
          props.p_editorState === EditorState.WIRE_SUMMARY ||
          props.p_editorState === EditorState.PROJECT_SUMMARY ||
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING
        ) {
          showPenetration(!m_controls.showPenetration);
        } else
          logger.warning(
            "Vous ne pouvez afficher la pénetration du fil que lorsqu'il est visible."
          );
      }
    }

    function _onMouseUp(e: Event): void {
      if (!(e.target as Element).closest("#sceneDiv")) return;
      document.getElementById("sceneDiv")!.focus(); // Focus the sceneDiv to be able to use the keyboard shortcuts
    }

    function _onGimbalRelease(p_manual: boolean) {
      if (props.p_editorState !== EditorState.WIRE_MANUAL_EDITING) return;
      // Update the wire mesh when the transform control is released. (Manual editing)
      m_jaw.updateWireMeshes();
      const selectedPoint = m_controls.getSelectedPointObject();
      if (selectedPoint) {
        const name = m_controls.getSelectedPointArcadeName();
        const id = m_controls.getSelectedPointId();
        if (sceneLeftToolbar.value?.gimbalType === GimbalType.NONE) {
          if (name === Constants.MESH_MAXILLA_NAME)
            m_jaw.maxi.wire.wireMesh.rotateWireGimbals(id);
          if (name === Constants.MESH_MANDIBLE_NAME)
            m_jaw.mandi.wire.wireMesh.rotateWireGimbals(id);
        }
        if (!_isDuplicate(selectedPoint) && p_manual)
          addActionToCtrlZHistory({
            type: CtrlZActionType.MOVE_POINT,
            name: selectedPoint.name,
            basePosition: new Vector3().copy(selectedPoint.position), //don't change cuz responsive :D
            normal: new Vector3(0),
            finalPosition: new Vector3(0),
          });
      }
    }

    function _isDuplicate(selectedPoint: Object3D): boolean {
      const lastAction = m_jaw.ctrlZHistory[m_jaw.ctrlZHistory.length - 1];
      if (lastAction) {
        //if the last action was the same pointmove, the user probably grabbed both transversal gimbals at once
        //which put the same position twice in the history which is annoying/confusing
        if (
          lastAction.type === CtrlZActionType.MOVE_POINT &&
          lastAction.name === selectedPoint.name &&
          lastAction.basePosition.equals(selectedPoint.position)
        )
          return true;
      }
      return false;
    }

    function _onKeyDown(e: KeyboardEvent): void {
      if (!(e.target as Element).closest("#sceneDiv")) return;

      if (props.p_editorState === EditorState.WIRE_MANUAL_EDITING) {
        // Detection de la flèche droite/haut pour avancer le point sur l'axe de gimbal
        if (e.key === "ArrowRight" || e.key === "ArrowUp") movePoint(true);
        // Detection de la flèche gauche/bas pour reculer le point sur l'axe de gimbal
        if (e.key === "ArrowLeft" || e.key === "ArrowDown") movePoint(false);
      }
    }

    function resizeScene(): void {
      const element: HTMLElement = scene.value as HTMLElement;
      m_controls.resizeScene(element);
    }

    function toggleCamera(): void {
      m_controls.toggleCamera();
    }

    function toggleWireframe(): void {
      m_jaw.maxi.scan.toggleWireframe();
      m_jaw.mandi.scan.toggleWireframe();
    }

    function toggleKeyMode(): void {
      m_jaw.maxi.toggleKeyMode();
      m_jaw.mandi.toggleKeyMode();
    }

    function loadWires(p_dataWires: string) {
      if (!p_dataWires) return;
      let jsonWires;
      try {
        jsonWires = JSON.parse(p_dataWires);
      } catch {
        Logger.getInstance().error("Invalid jsonWires JSON string in the URL");
        return;
      }
      m_jaw.loadWires(Object.values(jsonWires));

      Object.values(jsonWires).forEach((jsonWire: any) => {
        if (!jsonWire.history) {
          wiresInfos.value.push({
            isVisible: true,
          });
        }
      });
    }

    function unloadWires() {
      m_jaw.unloadWires();
    }

    function loadFiles(
      p_dataMaxilla: ArrayBuffer | null,
      p_dataMandible: ArrayBuffer | null
    ) {
      LoaderBus.emit("show");

      // Reset models.
      m_jaw.disposeAll();

      if (p_dataMaxilla) {
        loadFile(p_dataMaxilla, Arcade.MAXILLA);
      }
      if (p_dataMandible) {
        loadFile(p_dataMandible, Arcade.MANDIBLE);
      }

      placeFilesInScene(false);
      animateCamera();

      LoaderBus.emit("hide");
    }

    function animateCamera(): void {
      const camTargetPos = m_controls
        .getCameraDefaultPosition(_getSceneBBox())
        .clone();
      const camInitialPos = m_controls
        .getCameraDefaultPosition(_getSceneBBox())
        .clone()
        .add(new Vector3(0, 0, 1000));
      m_controls.camera.position.copy(camInitialPos);

      m_showOverlay.value = false;

      new TWEEN.Tween(m_controls.camera.position)
        .to(camTargetPos, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
    }

    function placeFilesInScene(resetCam = true) {
      _recenterScene();
      if (resetCam) m_controls.resetCamera(_getSceneBBox());

      if (WinnoveHelper.isDev()) {
        m_scene.children.forEach((child) => {
          if (child instanceof Box3Helper) m_scene.remove(child);
        });
        m_scene?.add(new Box3Helper(_getSceneBBox(), new Color(0xff0000)));
      }
    }

    function loadFile(p_data: ArrayBuffer, p_arcade: Arcade): void {
      if (p_arcade === Arcade.MAXILLA) {
        const loaded = m_jaw.maxi.loadFile(p_data);
        if (loaded) {
          sceneEventBus.emit("eventName", "maxiFileLoaded");
        } else {
          sceneEventBus.emit("eventName", "maxiFileError");
        }
      } else {
        const loaded = m_jaw.mandi.loadFile(p_data);
        if (loaded) {
          sceneEventBus.emit("eventName", "mandiFileLoaded");
        } else {
          sceneEventBus.emit("eventName", "mandiFileError");
        }
      }
    }

    function _recenterScene(): void {
      let center = _getSceneBBoxCenter();
      center.multiplyScalar(-1);
      // For old projects, the translation vector is not set, so we set it to the center of the scene.
      if (m_jaw.maxi.scan.mesh) {
        const maxiJawPos = new Vector3().setFromMatrixPosition(
          m_jaw.maxi.scan.mesh.matrixWorld
        );
        if (maxiJawPos.length() === 0) m_jaw.maxi.scan.translate(center);
      }

      if (m_jaw.mandi.scan.mesh) {
        const mandiJawPos = new Vector3().setFromMatrixPosition(
          m_jaw.mandi.scan.mesh.matrixWorld
        );
        if (mandiJawPos.length() === 0) m_jaw.mandi.scan.translate(center);
      }
    }

    function _getSceneBBox(): Box3 {
      let sceneBbox = new Box3();
      if (m_jaw.maxi.scan.mesh) {
        sceneBbox.union(new Box3().setFromObject(m_jaw.maxi.scan.mesh, true));
      }
      if (m_jaw.mandi.scan.mesh) {
        sceneBbox.union(new Box3().setFromObject(m_jaw.mandi.scan.mesh, true));
      }
      return sceneBbox;
    }

    function _getSceneBBoxCenter(): Vector3 {
      let sceneBbox = _getSceneBBox();
      let center = new Vector3();
      sceneBbox.getCenter(center);
      return center;
    }

    function viewLingual(): void {
      m_controls.viewLingual();
    }

    function viewOcclusal(): void {
      if (m_jaw.maxi.scan.isVisible() && m_jaw.mandi.scan.isVisible()) {
        logger.info("Cachez une des arcade pour passer en vue occlusal");
        return;
      }

      if (m_jaw.maxi.scan.isVisible()) {
        m_controls.viewOcclusal(Arcade.MAXILLA);
      } else if (m_jaw.mandi.scan.isVisible()) {
        m_controls.viewOcclusal(Arcade.MANDIBLE);
      } else {
        logger.info("Aucune arcade visible");
      }
    }

    function rotateLingual(): void {
      const axis: Vector3 = new Vector3(0, -1, 0);
      const angle: number = Math.PI / 2;

      //reset position to world origin to rotateOnWorldAxis correctly
      if (m_jaw.maxi.scan.mesh) m_jaw.maxi.scan.mesh.position.set(0, 0, 0);
      if (m_jaw.mandi.scan.mesh) m_jaw.mandi.scan.mesh.position.set(0, 0, 0);
      //rotate it then recenter it
      m_jaw.rotate(axis, angle);
      placeFilesInScene();
    }

    function applyMatrix4(p_matrix: Matrix4): void {
      //reset position to world origin to rotateOnWorldAxis correctly
      if (m_jaw.maxi.scan.mesh) m_jaw.maxi.scan.mesh.position.set(0, 0, 0);
      if (m_jaw.mandi.scan.mesh) m_jaw.mandi.scan.mesh.position.set(0, 0, 0);
      // apply matrix
      m_jaw.applyMatrix4(p_matrix);
    }

    function turnMaxilla(): void {
      const axis: Vector3 = new Vector3(0, 0, 1);
      const angle: number = Math.PI;

      m_jaw.maxi.rotate(axis, angle);
      placeFilesInScene();
    }

    function showMaxilla(
      p_value: boolean,
      p_renderPoints: boolean = true
    ): void {
      m_jaw.maxi.setVisible(
        p_value,
        p_renderPoints ? props.p_editorState : EditorState.PROJECT_SUMMARY,
        props.p_selectedArcade
      );
    }
    function isJawInverted(): boolean {
      return m_jaw.isJawInverted();
    }

    function invertJawMandiMaxi(): void {
      m_jaw.invertJawMandiMaxi();
    }

    function showMandible(
      p_value: boolean,
      p_renderPoints: boolean = true
    ): void {
      m_jaw.mandi.setVisible(
        p_value,
        p_renderPoints ? props.p_editorState : EditorState.PROJECT_SUMMARY,
        props.p_selectedArcade
      );
    }

    function toggleOpacity(): void {
      if (m_jaw.maxi) m_jaw.maxi.scan.toggleOpacity();
      if (m_jaw.mandi) m_jaw.mandi.scan.toggleOpacity();
    }

    function raycast(p_event: MouseEvent): void {
      if (
        props.p_editorState !== EditorState.WIRE_DRAWING &&
        props.p_editorState !== EditorState.WIRE_MANUAL_EDITING &&
        props.p_editorState !== EditorState.PLANE_DRAWING
      ) {
        return;
      }

      // Check if we are in freemium mode.
      if (props.p_privilege == Privilege.FREEMIUM) {
        logger.warning(
          "Les fonctionnalités sont limitées en raison du profil freemium."
        );
        return;
      }

      // Build the mouse vector from the event.
      const mouse = new Vector2();
      const rect: DOMRect =
        m_controls.renderer.domElement.getBoundingClientRect();
      mouse.x = ((p_event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((p_event.clientY - rect.top) / rect.height) * 2 + 1;

      // Perform the raycast.
      const raycaster = new Raycaster();
      raycaster.setFromCamera(mouse, m_controls.camera);
      const intersections: Array<Intersection> = raycaster.intersectObjects(
        m_scene.children,
        true
      );
      // Check if we have an intersection.
      for (const intersection of intersections) {
        if (intersection.object.visible) {
          const name = intersection.object.name;

          if (
            props.p_editorState === EditorState.WIRE_DRAWING ||
            props.p_editorState === EditorState.PLANE_DRAWING
          ) {
            // Add point.
            let arcade: Arcade = Arcade.NONE;
            if (name == Constants.MESH_MAXILLA_NAME) {
              arcade = Arcade.MAXILLA;
            } else if (name == Constants.MESH_MANDIBLE_NAME) {
              arcade = Arcade.MANDIBLE;
            }

            if (arcade !== Arcade.NONE && props.p_selectedArcade !== arcade) {
              logger.warning(
                "Les modifications ne sont possibles que sur l'arcade sélectionnée."
              );
              return;
            }

            if (arcade !== Arcade.NONE) {
              const infos = _addPoint(
                arcade,
                intersection.point.clone(),
                intersection
                  .face!.normal.clone()
                  .transformDirection(intersection.object.matrixWorld)
                  .normalize(),
                props.p_editorState === EditorState.WIRE_DRAWING
                  ? PointType.BASE
                  : PointType.PLANE
              );
              if (infos) addActionToCtrlZHistory(infos);
              return;
            }

            // Remove point.
            if (_removePoint(name, true)) return;
          }
          // EditorState.WIRE_MANUAL_EDITING
          // Selects point.
          else if (name.startsWith(Constants.MESH_MAXILLA_NAME + "|")) {
            _selectPoint(Arcade.MAXILLA, name); // Select point on maxilla.
            return;
          } else if (name.startsWith(Constants.MESH_MANDIBLE_NAME + "|")) {
            _selectPoint(Arcade.MANDIBLE, name); // Select point on mandible.
            return;
          } else if (
            name == Constants.MESH_MAXILLA_NAME ||
            name == Constants.MESH_MANDIBLE_NAME
          ) {
            _unselectPoints(); // If no action was performed, unselects all points.
          }
        }
      }
    }

    function _removePoint(p_name: string, p_manual: boolean): boolean {
      let infos;

      if (props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
        _unselectPoints();
      if (p_name.startsWith(Constants.MESH_MAXILLA_NAME)) {
        if (props.p_editorState === EditorState.PLANE_DRAWING) {
          infos = m_jaw.maxi.wire.plane.removePoint(p_name);
          m_jaw.maxi.setPlaneLineVisible(true);
        } else infos = m_jaw.maxi.wire.wireMesh.removePoint(p_name);
        m_jaw.maxi.updateZonePoints();
        if (p_manual && infos) addActionToCtrlZHistory(infos);
        return true;
      } else if (p_name.startsWith(Constants.MESH_MANDIBLE_NAME)) {
        if (props.p_editorState === EditorState.PLANE_DRAWING) {
          infos = m_jaw.mandi.wire.plane.removePoint(p_name);
          m_jaw.mandi.setPlaneLineVisible(true);
        } else infos = m_jaw.mandi.wire.wireMesh.removePoint(p_name);
        m_jaw.mandi.updateZonePoints();
        if (p_manual && infos) addActionToCtrlZHistory(infos);
        return true;
      }
      return false;
    }

    function _selectPoint(p_arcade: Arcade, p_name: string): void {
      // Get id.
      const id = parseInt(p_name.split("|")[1]);
      // If the point is already selected, unselect it.
      if (m_controls.getSelectedPointObject()?.name === p_name) {
        _unselectPoints();
      } else {
        // Else unselect all points and select the new one.
        _unselectPoints();
        if (p_arcade === Arcade.MAXILLA) m_jaw.maxi.wire.selectPoint(id);
        else m_jaw.mandi.wire.selectPoint(id);
        _applyGimbal(p_arcade, id);
      }
    }

    function _applyGimbal(p_arcade: Arcade, p_id: number) {
      if (sceneLeftToolbar.value?.gimbalType === GimbalType.BASE)
        return m_controls.setBaseGimbal();
      if (sceneLeftToolbar.value?.gimbalType === GimbalType.PLANE) {
        if (p_arcade === Arcade.MAXILLA && m_jaw.maxi.wire.plane.has2DPlane())
          return m_controls.setPlaneGimbal(
            m_jaw.maxi.wire.plane.getPlaneMesh()!.rotation
          );
        else if (
          p_arcade === Arcade.MANDIBLE &&
          m_jaw.mandi.wire.plane.has2DPlane()
        )
          return m_controls.setPlaneGimbal(
            m_jaw.mandi.wire.plane.getPlaneMesh()!.rotation
          );
        else {
          Logger.getInstance().info(
            "Retour à la gimbal 3D car l'arcade du point sélectionné n'a pas de plan."
          );
          return sceneLeftToolbar.value.setGimbalType(
            m_controls.setBaseGimbal()
          );
        }
      }

      setWireGimbal(p_arcade, p_id);
    }

    function _unselectPoints() {
      m_jaw.maxi.wire.wireMesh.unselectPoints();
      m_jaw.mandi.wire.wireMesh.unselectPoints();
      m_controls.translateControls[GimbalIndex.BASE_PLANE_ANTERO]?.detach();
      m_controls.translateControls.forEach((p_control, p_id) => {
        if (p_id > 0)
          p_control.showX = p_control.showY = p_control.showZ = false;
      });
    }

    function _addPoint(
      p_arcade: Arcade,
      p_intersection: Vector3,
      p_normal: Vector3,
      p_pointType: PointType,
      p_id: number = -1
    ): CtrlZAction | undefined {
      return m_jaw.addPoint(
        p_arcade,
        p_intersection,
        p_normal,
        p_pointType,
        p_id,
        m_controls.camera.position
      );
    }

    watch(
      () => props.p_project,
      () => {
        m_jaw.updateProject(props.p_project);
      }
    );

    watch(
      () => props.p_editorState,
      () => {
        onEditorStateChange();
      }
    );

    watch(
      () => props.p_selectedArcade,
      () => {
        unloadWires();
        m_wiresHistory.value = props.p_project.getWiresHistory(
          props.p_selectedArcade
        );
        onEditorStateChange();
      }
    );

    function onEditorStateChange(): void {
      loadWires(props.p_query);
      m_jaw.updateProject(props.p_project);
      _unselectPoints();
      m_jaw.maxi.disposeAllButScan();
      m_jaw.mandi.disposeAllButScan();
      m_jaw.loadPoints(PointType.PLANE);

      if (
        props.p_editorState === EditorState.WIRE_DRAWING ||
        props.p_editorState === EditorState.PLANE_DRAWING
      ) {
        // If we are drawing, we load the base points.
        if (props.p_selectedArcade === Arcade.MAXILLA) {
          // we load the base points of the selected arcade, the wire points for the other.
          m_jaw.maxi.loadPoints(PointType.BASE);
          m_jaw.mandi.loadPoints(PointType.WIRE);
        } else if (props.p_selectedArcade === Arcade.MANDIBLE) {
          m_jaw.maxi.loadPoints(PointType.WIRE);
          m_jaw.mandi.loadPoints(PointType.BASE);
        } else m_jaw.loadPoints(PointType.WIRE);
      } else m_jaw.loadPoints(PointType.WIRE);

      m_jaw.updateScene(props.p_editorState, props.p_selectedArcade);
      m_jaw.ctrlZHistory = m_jaw.ctrlYHistory = [];
      isCtrlZEmpty.value = isCtrlYEmpty.value = true;
      if (
        (props.p_selectedArcade === Arcade.MAXILLA &&
          m_jaw.maxi.wire.plane.has2DPlane()) ||
        (props.p_selectedArcade === Arcade.MANDIBLE &&
          m_jaw.mandi.wire.plane.has2DPlane())
      )
        sceneLeftToolbar.value?.setGimbalType(GimbalType.PLANE);
      else sceneLeftToolbar.value?.setGimbalType(GimbalType.BASE);

      if (
        m_jaw.jsonWires.length &&
        (props.p_editorState === EditorState.WIRE_SUMMARY ||
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
      ) {
        wiresInfos.value.length = m_jaw.jsonWires.length + 1;
        wiresInfos.value[wiresInfos.value.length - 1] = {
          isVisible: true,
        };
      } else wiresInfos.value.length = m_jaw.jsonWires.length;
    }

    function showOcclusion(
      p_value: boolean,
      p_occlusion: string | null = null
    ): void {
      if (
        m_jaw.maxi.scan.mesh &&
        (m_jaw.maxi.isOcclusionComputed() || p_occlusion)
      ) {
        m_showOcclusion = p_value;
        showMaxilla(true);
        sceneViewToolbar.value?.setShowMaxilla(true);
        m_jaw.maxi.showOcclusion(p_value, p_occlusion);
      }
    }

    function resetOcclusion() {
      m_showOcclusion = false;
      sceneViewToolbar.value?.showOcclusion(false);
      m_jaw.maxi.showOcclusion(false, null);
    }

    function getRendereredData(): string {
      return m_controls.getRendereredData();
    }

    function getRendereredDataSnapshots(): [string, string] {
      // Data.
      let dataMaxilla = "";
      let dataMandible = "";

      // Create a new renderer.
      const renderer = new WebGLRenderer({
        antialias: true,
      });
      renderer.setClearColor(0x000000, 0);
      renderer.setSize(
        Constants.SNAPSHOT_WIDTH,
        Constants.SNAPSHOT_WIDTH * Constants.SNAPSHOT_RATIO
      );

      // Create the camera.
      const camera = new PerspectiveCamera(
        45,
        Constants.SNAPSHOT_WIDTH /
          (Constants.SNAPSHOT_WIDTH * Constants.SNAPSHOT_RATIO),
        1,
        1000
      );

      // Reset controls
      _unselectPoints();

      // Save configuration.
      const maxillaVisibility = m_jaw.maxi.scan.mesh?.visible;
      const mandibleVisibility = m_jaw.mandi.scan.mesh?.visible;
      const showOcclusionSaved = m_showOcclusion;
      const showPenetrationSaved = m_controls.showPenetration;
      showOcclusion(false);

      showPenetration(false);

      // Maxilla.
      if (m_jaw.maxi.wire.wireMesh.mesh) {
        showMaxilla(true, false);
        showMandible(false, false);
        m_jaw.maxi.wire.wireMesh.mesh.geometry.computeBoundingBox(); // ensure bounding box is computed
        const bbox = new Box3().setFromObject(
          m_jaw.maxi.wire.wireMesh.mesh,
          true
        );
        let center = new Vector3();
        bbox.getCenter(center);
        camera.position.copy(
          getCameraDefaultPositionFromBBox(
            camera,
            bbox,
            Constants.SNAPSHOT_PADDING
          )
        );
        camera.position.setX(0);
        camera.lookAt(center);
        renderer.render(m_scene, camera);
        dataMaxilla = renderer.domElement.toDataURL("image/png");
      }

      // Mandible.
      if (m_jaw.mandi.wire.wireMesh.mesh) {
        showMandible(true, false);
        showMaxilla(false, false);
        m_jaw.mandi.wire.wireMesh.mesh.geometry.computeBoundingBox(); // ensure bounding box is computed
        const bbox = new Box3().setFromObject(
          m_jaw.mandi.wire.wireMesh.mesh,
          true
        );
        let center = new Vector3();
        bbox.getCenter(center);
        camera.position.copy(
          getCameraDefaultPositionFromBBox(
            camera,
            bbox,
            Constants.SNAPSHOT_PADDING
          )
        );
        camera.position.setX(0);
        camera.lookAt(center);
        renderer.render(m_scene, camera);
        dataMandible = renderer.domElement.toDataURL("image/png");
      } else if (m_jaw.jsonWires.length) {
        // if there is no mandi wire, but there is a jsonWire, we render the mandi snapshot
        showMandible(true, false);
        showMaxilla(false, false);
        // m_jaw.mandi.wire.wireMesh.mesh.geometry.computeBoundingBox(); // ensure bounding box is computed
        const bbox = new Box3().setFromObject(m_jaw.jsonWires[0], true);
        let center = new Vector3();
        bbox.getCenter(center);
        camera.position.copy(
          getCameraDefaultPositionFromBBox(
            camera,
            bbox,
            Constants.SNAPSHOT_PADDING
          )
        );
        camera.position.setX(0);
        camera.lookAt(center);
        renderer.render(m_scene, camera);
        dataMandible = renderer.domElement.toDataURL("image/png");
      }

      // Restore configuration.
      if (maxillaVisibility) {
        showMaxilla(maxillaVisibility, true);
      }
      if (mandibleVisibility) {
        showMandible(mandibleVisibility, true);
      }
      showOcclusion(showOcclusionSaved);
      showPenetration(showPenetrationSaved);

      // Clean.
      renderer.renderLists.dispose();
      renderer.dispose();

      return [dataMaxilla, dataMandible];
    }

    function getPoints(p_arcade: Arcade, p_pointType: PointType): string {
      return m_jaw.getPoints(p_arcade, p_pointType);
    }

    function getPointsNb(p_arcade: Arcade, p_pointType: PointType): number {
      return m_jaw.getPointsNb(p_arcade, p_pointType);
    }

    function getTransform(p_arcade: Arcade): Matrix4 | null {
      return m_jaw.getScanMatrix(p_arcade);
    }

    function isOcclusionComputed(): boolean {
      return m_jaw.maxi.isOcclusionComputed();
    }

    function exportWireMesh(p_arcade: Arcade) {
      return m_jaw.exportWireMesh(p_arcade);
    }

    function exportWireMeshAndJawTogether(p_arcade: Arcade) {
      return m_jaw.exportWireMeshAndJawTogether(p_arcade);
    }

    function resetGroupMeshRotation() {
      m_jaw.meshesGroup.setRotationFromEuler(new Euler(0, 0, 0));
      m_jaw.meshesGroup.updateMatrixWorld(true);
    }

    function toggleRotationTool(p_save: boolean) {
      //when the rotate mode is accessed (r), get the 2 arcade meshes,
      //attach them to the rotatecontrols, activate lingual view
      if (!rotationToolActive.value) {
        m_jaw.meshesGroup.setRotationFromEuler(new Euler(0, 0, 0));
        if (m_jaw.maxi.scan.mesh) m_jaw.meshesGroup.add(m_jaw.maxi.scan.mesh);
        if (m_jaw.mandi.scan.mesh) m_jaw.meshesGroup.add(m_jaw.mandi.scan.mesh);
        m_controls.rotateControl.attach(m_jaw.meshesGroup);
        m_controls.viewLingual();
      }
      //when you leave it (by quitting with r again or "Annuler" or saving with "Sauvegarder"),
      //revert back the arcades to their original rotation or keep it if saved
      else {
        // not saved, revert back to original rotation
        if (!p_save) {
          m_jaw.meshesGroup.setRotationFromEuler(new Euler(0, 0, 0));
          m_jaw.meshesGroup.updateMatrixWorld(true);
          m_jaw.resetScanMatrix();
        }
        m_controls.rotateControl.detach();
        while (m_jaw.meshesGroup.children.length > 0) {
          const child = m_jaw.meshesGroup.children[0];
          m_jaw.meshesGroup.remove(child);
          if (p_save) child.applyMatrix4(m_jaw.meshesGroup.matrixWorld);
          m_scene.add(child);
        }
      }
      //toggle the tool
      rotationToolActive.value = !rotationToolActive.value;
      //camera rotation isn't allowed in rotation mode
      m_controls.selectedControl.enableRotate =
        !m_controls.selectedControl.enableRotate;
      m_controls.selectedControl.enablePan =
        !m_controls.selectedControl.enablePan;
      placeFilesInScene();
    }

    function ctrlZ() {
      //pop the last element in the ctrlz actions history list
      //if there's none after, disable the ctrlz button
      let lastAction = m_jaw.ctrlZHistory.pop();
      isCtrlZEmpty.value = !m_jaw.ctrlZHistory.length;

      //if there's no action to go back to, do nothing
      if (!lastAction) return;

      //if the action is an addpoint, add the point with its base position,
      //move it to its current position (so that it's the exact same point as before)
      //update the wire and put in cltry the removepoint equivalent
      if (lastAction.type === CtrlZActionType.ADD_POINT) {
        const pointId = parseInt(lastAction.name.split("|")[1]);

        const arcade = lastAction.name.startsWith(Constants.MESH_MAXILLA_NAME)
          ? Arcade.MAXILLA
          : Arcade.MANDIBLE;

        let pointType: PointType = PointType.BASE;
        if (props.p_editorState === EditorState.PLANE_DRAWING)
          pointType = PointType.PLANE;
        else if (props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
          pointType = PointType.WIRE;

        _addPoint(
          arcade,
          lastAction.basePosition,
          lastAction.normal,
          pointType,
          pointId
        );

        if (props.p_editorState === EditorState.WIRE_MANUAL_EDITING) {
          if (arcade === Arcade.MAXILLA)
            m_jaw.maxi.changePosition(pointId, lastAction.finalPosition);
          else m_jaw.mandi.changePosition(pointId, lastAction.finalPosition);
        }
        _onGimbalRelease(false);
        lastAction.type = CtrlZActionType.REMOVE_POINT;
        m_jaw.ctrlYHistory.push(lastAction);
      }

      //if the action is a removepoint, remove the point with its name,
      //put in cltry the addpoint equivalent
      else if (lastAction.type === CtrlZActionType.REMOVE_POINT) {
        _removePoint(lastAction.name, false);
        lastAction.type = CtrlZActionType.ADD_POINT;
        m_jaw.ctrlYHistory.push(lastAction);
      }

      //if the action is a movepoint, revert its position with its name and current position
      //put in cltry the same action (check _revertPos to see how it works)
      else if (lastAction.type === CtrlZActionType.MOVE_POINT) {
        _revertPos(lastAction, true);
        m_jaw.ctrlYHistory.push(lastAction);
      }

      //if a ctrlz has been done, ctrly can be used
      isCtrlYEmpty.value = false;
    }

    function ctrlY() {
      //pop the last element in the ctrly actions history list
      //if there's none after, disable the ctrly button
      let lastAction = m_jaw.ctrlYHistory.pop();
      isCtrlYEmpty.value = !m_jaw.ctrlYHistory.length;

      //if there's no action to go back to, do nothing
      if (!lastAction) return;

      //if the action is an addpoint, add the point with its base position,
      //update the wire and put in cltrz the removepoint equivalent
      if (lastAction.type == CtrlZActionType.ADD_POINT) {
        const arcade = lastAction.name.startsWith(Constants.MESH_MAXILLA_NAME)
          ? Arcade.MAXILLA
          : Arcade.MANDIBLE;

        let pointType: PointType = PointType.BASE;
        if (props.p_editorState === EditorState.PLANE_DRAWING)
          pointType = PointType.PLANE;
        else if (props.p_editorState === EditorState.WIRE_MANUAL_EDITING)
          pointType = PointType.WIRE;

        _addPoint(
          arcade,
          lastAction.basePosition,
          lastAction.normal,
          pointType,
          parseInt(lastAction.name.split("|")[1])
        );

        _onGimbalRelease(false);
        lastAction.type = CtrlZActionType.REMOVE_POINT;
        m_jaw.ctrlZHistory.push(lastAction);
      }

      //if the action is a removepoint, remove the point with its name,
      //update the wire and put in cltrz the addpoint equivalent
      else if (lastAction.type == CtrlZActionType.REMOVE_POINT) {
        _removePoint(lastAction.name, false);
        _onGimbalRelease(false);
        lastAction.type = CtrlZActionType.ADD_POINT;
        m_jaw.ctrlZHistory.push(lastAction);
      }

      //if the action is a movepoint, revert its position with its name and current position
      //put in cltrz the same action (check _revertPos to see how it works)
      else if (lastAction.type == CtrlZActionType.MOVE_POINT) {
        _revertPos(lastAction, false);
        m_jaw.ctrlZHistory.push(lastAction);
      }

      //if a ctrly has been done, ctrlz can be used
      isCtrlZEmpty.value = false;
    }

    function _revertPos(elements: CtrlZAction, p_isCtrlZ: boolean) {
      const id = parseInt(elements.name.split("|")[1]);
      let newPos;

      // When moving the selected object with a gimbal axis, we save its position.
      // "elements" contains the last position of a point that was popped from the history.
      // However, we don't want to go back to that position (we still save it just in case
      // we continue moving this point), but rather to the previous one. So, we search the history
      // from the end to find its previous position. If it doesn't have one (first change),
      // "newPos" remains undefined, otherwise it is the found position.
      for (let i = m_jaw.ctrlZHistory.length - 1; i >= 0; i--) {
        if (m_jaw.ctrlZHistory[i].name === elements.name) {
          if (m_jaw.ctrlZHistory[i].type === CtrlZActionType.MOVE_POINT)
            newPos = m_jaw.ctrlZHistory[i].basePosition;
          break;
        }
      }

      //if the revertpos was done from a ctrly, that means the point got its position reverted
      //by ctrlz before and we want to cancel that revert so we just copy back its position
      //if newppos is undefined (first change), we set it to its .p (original position) (ctrlz)
      //if not, we set the point position to it (ctrlz)
      if (!p_isCtrlZ) {
        if (elements.name.startsWith(Constants.MESH_MAXILLA_NAME))
          m_jaw.maxi.changePosition(id, elements.basePosition);
        else m_jaw.mandi.changePosition(id, elements.basePosition);
      } else if (!newPos) {
        if (elements.name.startsWith(Constants.MESH_MAXILLA_NAME))
          m_jaw.maxi.changePosition(id, m_jaw.maxi.getPointPosition(id));
        else m_jaw.mandi.changePosition(id, m_jaw.mandi.getPointPosition(id));
      } else if (elements.name.startsWith(Constants.MESH_MAXILLA_NAME))
        m_jaw.maxi.changePosition(id, newPos);
      else m_jaw.mandi.changePosition(id, newPos);

      //update the wire and the gimbals positions after moving the point
      _onGimbalRelease(false);
      m_controls.translateControls.forEach((p_temp, p_id) => {
        if (p_id > 0 && p_temp.object && m_controls.getSelectedPointObject())
          p_temp.object.position.copy(
            m_controls.getSelectedPointObject()!.position
          );
      });
    }

    function snapOnPlane(p_arcade: Arcade): boolean {
      if (p_arcade === Arcade.MANDIBLE) {
        return m_jaw.mandi.snapOnPlane();
      } else if (p_arcade === Arcade.MAXILLA) {
        return m_jaw.maxi.snapOnPlane();
      } else {
        return false;
      }
    }

    function insertPoint() {
      if (!m_controls.getSelectedPointObject()) {
        return logger.warning(
          "Impossible d'insérer un point car aucun point n'est sélectionné. Sélectionnez un point avec click droit puis réessayez."
        );
      }
      const name = m_controls.getSelectedPointArcadeName();
      const id = m_controls.getSelectedPointId();
      let infos;
      if (name === Constants.MESH_MAXILLA_NAME) {
        infos = m_jaw.maxi.wire.wireMesh.insertWirePoint(id);
        m_jaw.maxi.updateWireMesh();
      }
      if (name === Constants.MESH_MANDIBLE_NAME) {
        infos = m_jaw.mandi.wire.wireMesh.insertWirePoint(id);
        m_jaw.mandi.updateWireMesh();
      }
      if (infos) addActionToCtrlZHistory(infos);
    }

    function removePlane(p_arcade: Arcade) {
      if (p_arcade === Arcade.MANDIBLE) {
        m_jaw.mandi.wire.plane.disposeAll();
        m_jaw.mandi.setPlaneLineVisible(false);
      } else if (p_arcade === Arcade.MAXILLA) {
        m_jaw.maxi.wire.plane.disposeAll();
        m_jaw.maxi.setPlaneLineVisible(false);
      }
    }

    function removePoint() {
      if (!m_controls.getSelectedPointObject()) {
        return logger.warning(
          "Impossible de supprimer un point car aucun point n'est sélectionné. Sélectionnez un point avec click droit puis réessayez."
        );
      }
      const name = m_controls.getSelectedPointObject()!.name;
      _unselectPoints();
      let infos;
      if (name.startsWith(Constants.MESH_MAXILLA_NAME)) {
        if (m_jaw.maxi.wire.wireMesh.getPointsNb() > 3) {
          infos = m_jaw.maxi.wire.wireMesh.removePoint(name);
          m_jaw.maxi.updateWireMesh();
        } else logger.warning("Le fil doit avoir au moins 3 points.");
      }
      if (name.startsWith(Constants.MESH_MANDIBLE_NAME)) {
        if (m_jaw.mandi.wire.wireMesh.getPointsNb() > 3) {
          infos = m_jaw.mandi.wire.wireMesh.removePoint(name);
          m_jaw.mandi.updateWireMesh();
        } else logger.warning("Le fil doit avoir au moins 3 points.");
      }
      if (infos) addActionToCtrlZHistory(infos);
    }

    function toggleGimbal(p_gimbalType: GimbalType) {
      //si plane ou world est déjà activé, revenir au default
      if (
        p_gimbalType < GimbalType.ANTERO_POSTERIOR &&
        sceneLeftToolbar.value?.gimbalType === p_gimbalType
      )
        p_gimbalType = resetToDefaultGimbal();
      sceneLeftToolbar.value?.toggleGimbalType(p_gimbalType);
      //si aucun wiregimbal n'est actif, revenir au default
      if (
        p_gimbalType >= GimbalType.ANTERO_POSTERIOR &&
        !sceneLeftToolbar.value?.isGimbalAntero &&
        !sceneLeftToolbar.value?.isGimbalTransversal &&
        !sceneLeftToolbar.value?.isGimbalVertical
      )
        p_gimbalType = resetToDefaultGimbal();
      if (!m_controls.getSelectedPointObject()) return;
      const name = m_controls.getSelectedPointObject()!.name;
      if (p_gimbalType === GimbalType.BASE) return m_controls.setBaseGimbal();
      if (p_gimbalType === GimbalType.PLANE) return setPlaneGimbal(name);
      setWireGimbal(
        name.startsWith(Constants.MESH_MAXILLA_NAME)
          ? Arcade.MAXILLA
          : Arcade.MANDIBLE,
        parseInt(name.split("|")[1])
      );
    }

    function resetToDefaultGimbal(): GimbalType {
      if (
        (props.p_selectedArcade === Arcade.MAXILLA &&
          m_jaw.maxi.wire.plane.has2DPlane()) ||
        (props.p_selectedArcade === Arcade.MANDIBLE &&
          m_jaw.mandi.wire.plane.has2DPlane())
      )
        return sceneLeftToolbar.value!.setGimbalType(
          m_controls.setPlaneGimbal()
        );
      else
        return sceneLeftToolbar.value!.setGimbalType(
          m_controls.setBaseGimbal()
        );
    }

    function setPlaneGimbal(p_name: string) {
      if (p_name.startsWith(Constants.MESH_MAXILLA_NAME)) {
        if (m_jaw.maxi.wire.plane.has2DPlane()) {
          return m_controls.setPlaneGimbal(
            m_jaw.maxi.wire.plane.getPlaneMesh()!.rotation
          );
        } else logger.warning("Le maxillaire n'a pas de plan.");
      }
      if (p_name.startsWith(Constants.MESH_MANDIBLE_NAME)) {
        if (m_jaw.mandi.wire.plane.has2DPlane()) {
          return m_controls.setPlaneGimbal(
            m_jaw.mandi.wire.plane.getPlaneMesh()!.rotation
          );
        } else logger.warning("La mandibulaire n'a pas de plan.");
      }
      return sceneLeftToolbar.value?.setGimbalType(m_controls.setBaseGimbal());
    }

    //fonction appelée quand il faut setup une wiregimbal (antero/transversal/vertical) lorsqu'on
    //appuie sur le bouton/raccourci clavier ou sélectionne un autre point, elle rotate chacune des gimbals,
    //appelle m_controls.setWireGimbal() (qui cache tous les axes de la gimbal principale et met tous les meshes
    //cachés sur le point selectionné) puis affiche les axes des gimbals en fonction de ce qui est activé
    function setWireGimbal(p_arcade: Arcade, p_id: number) {
      //rotate chacune des gimbals (update leur rotation par rapport au point sélectionné)
      if (p_arcade === Arcade.MAXILLA)
        m_jaw.maxi.wire.wireMesh.rotateWireGimbals(p_id);
      else m_jaw.mandi.wire.wireMesh.rotateWireGimbals(p_id);

      //appelle m_controls.setWireGimbal() et set le gimbalType de sceneLeftToolbar à NONE (surtout pour pas que
      //ca reste BASE ou PLANE)
      sceneLeftToolbar.value?.setGimbalType(m_controls.setWireGimbal());

      //cache ou affiche les axes des wiregimbals en fonctions des booléens correspondants dans sceneLeftToolbar
      m_controls.translateControls[GimbalIndex.BASE_PLANE_ANTERO].showZ =
        !!sceneLeftToolbar.value?.isGimbalAntero;
      m_controls.translateControls[GimbalIndex.TRANSVERSAL_1].showX =
        m_controls.translateControls[GimbalIndex.TRANSVERSAL_2].showX =
          !!sceneLeftToolbar.value?.isGimbalTransversal;
      m_controls.translateControls[GimbalIndex.VERTICAL].showY =
        !!sceneLeftToolbar.value?.isGimbalVertical;
    }

    function setDistanceCheck(p_value: boolean) {
      m_jaw.maxi.wire.wireMesh.setCheckMinDistance(p_value);
      m_jaw.mandi.wire.wireMesh.setCheckMinDistance(p_value);
    }

    function movePoint(p_positive: boolean) {
      if (!m_controls.getSelectedPointObject()) {
        return logger.warning(
          "Impossible de bouger un point car aucun point n'est sélectionné. Sélectionnez un point avec click droit puis réessayez."
        );
      }
      const name = m_controls.getSelectedPointArcadeName();
      const id = m_controls.getSelectedPointId();
      const gimbalType = sceneLeftToolbar.value?.getWireGimbals();

      if (gimbalType?.length === 1) {
        if (name === Constants.MESH_MAXILLA_NAME)
          m_jaw.maxi.wire.wireMesh.movePoint(p_positive, id, gimbalType[0]);
        else m_jaw.mandi.wire.wireMesh.movePoint(p_positive, id, gimbalType[0]);
      }
      m_controls.translateControls.forEach((p_temp, p_id) => {
        if (p_id > 0)
          p_temp.object!.position.copy(
            m_controls.getSelectedPointObject()!.position
          );
      });
    }

    function showContactPoints(p_show: boolean) {
      context.emit("show-contact-point", p_show);
    }

    function showZones(p_show: boolean) {
      sceneViewToolbar.value?.setShowZones(p_show);
      m_controls.showZones = p_show;
      // update zones visibility
      m_jaw.maxi.updateZonePoints();
      m_jaw.mandi.updateZonePoints();
    }

    function showPenetration(p_show: boolean) {
      sceneViewToolbar.value?.setShowPenetration(p_show);
      m_controls.showPenetration = p_show;
      // update penetration visibility
      m_jaw.maxi.updatePenetration();
      m_jaw.mandi.updatePenetration();
    }

    function addActionToCtrlZHistory(p_infos: CtrlZAction) {
      m_jaw.ctrlZHistory.push(p_infos);
      isCtrlZEmpty.value = false;
      m_jaw.ctrlYHistory = [];
      isCtrlYEmpty.value = true;
    }

    function toggleWire(p_index: number, p_selectedArcade: Arcade) {
      const isVisible = m_jaw.toggleWire(p_index, p_selectedArcade);
      if (isVisible != undefined)
        wiresInfos.value.splice(p_index, 1, {
          isVisible: isVisible,
        });
    }

    function reloadWireHistory(wireId: number) {
      const wiresHistory = useRepo(WireHistory)
        .query()
        .where("wireId", wireId)
        .orderBy("timestamp", "desc")
        .withAllRecursive(1)
        .get();
      m_wiresHistory.value = wiresHistory;
    }

    function getAutomaticArchOrientation() {
      context.emit("get-automatic-arch-orientation");
    }

    function getAIsnapshots(wiresJsonArray: string) {
      const temporaraySnapshotArray: string[] = [];
      // for each wireJson in the array, load the wire and get the snapshot
      JSON.parse(wiresJsonArray).forEach((wireJson: string) => {
        loadWires(wireJson);
        const snapshots = getRendereredDataSnapshots();
        temporaraySnapshotArray.push(snapshots[1]); // we only need the mandible snapshot
        unloadWires();
      });
      // set the snapshot array
      m_aiSnapshotArray.value = temporaraySnapshotArray;
    }

    function selectControl(p_isArcball: boolean) {
      m_controls.useArcballControls(p_isArcball);
      if (!p_isArcball) m_controls.viewLingual();
    }

    function submitWireChoice(p_AIwirePoints: string, p_aiChoice: AlgoVersion) {
      context.emit("setWireFromAIProposal", p_AIwirePoints, p_aiChoice);
    }

    return {
      Privilege,
      scene,
      rotationToolActive,
      sceneViewToolbar,
      sceneLeftToolbar,
      sceneViewAIWireChoice,
      isCtrlZEmpty,
      isCtrlYEmpty,
      wiresInfos,
      m_wiresHistory,
      m_aiSnapshotArray,
      invertJawMandiMaxi,
      isJawInverted,
      toggleRotationTool,
      toggleCamera,
      toggleWireframe,
      toggleKeyMode,
      loadFiles,
      loadWires,
      unloadWires,
      placeFilesInScene,
      loadFile,
      submitWireChoice,
      viewLingual,
      viewOcclusal,
      rotateLingual,
      turnMaxilla,
      showMaxilla,
      showMandible,
      toggleOpacity,
      showOcclusion,
      resetOcclusion,
      onEditorStateChange,
      getRendereredData,
      getRendereredDataSnapshots,
      getPoints,
      getPointsNb,
      getTransform,
      isOcclusionComputed,
      exportWireMesh,
      exportWireMeshAndJawTogether,
      raycast,
      snapOnPlane,
      showContactPoints,
      showZones,
      showPenetration,
      insertPoint,
      removePlane,
      removePoint,
      toggleGimbal,
      movePoint,
      setDistanceCheck,
      ctrlZ,
      ctrlY,
      toggleWire,
      reloadWireHistory,
      resizeScene,
      getAutomaticArchOrientation,
      applyMatrix4,
      resetGroupMeshRotation,
      getAIsnapshots,
      selectControl,
      animateCamera,
      mdiAutoFix,
      mdiCheckboxMarkedCircle,
      m_showOverlay,
    };
  },
});
</script>

<style lang="scss">
.tool-card {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  text-align: center;
}

#gui {
  position: absolute;
  top: 72px !important;
  left: 220px !important;

  @media screen and (max-width: 960px) {
    display: none !important;
  }
}

#stats {
  position: absolute;
  top: 72px !important;
  left: 120px !important;

  @media screen and (max-width: 960px) {
    right: 0 !important;
    top: 128px !important;
    left: auto !important;
  }
}

#sceneLeftToolbarDiv {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

#sceneViewToolbarDiv {
  position: absolute;
  right: 10px;
  @media screen and (max-width: 960px) {
    bottom: 18px;
  }
  @media screen and (min-width: 960px) {
    top: 10px;
  }
}

#sceneViewAIWireChoiceDiv {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 300px;
}
</style>

<template>
  <w-container fluid>
    <w-data-table-server
      :headers="m_headers"
      :items="m_projects"
      :items-length="m_totalCount"
      :search="m_search"
      :page="m_page"
      :items-per-page="m_itemsPerPage"
      :sortBy="m_options.sortBy"
      :hover="true"
      item-key="id"
      fixed-header
      class="text-primary"
      @update:options="optionsChanged"
    >
      <template v-slot:top>
        <div
          id="search-bar-container"
          class="d-flex"
        >
          <w-text-field
            data-cy="admin-search-project"
            v-model="m_search"
            label="Rechercher"
            class="mx-4 mt-2"
            :prepend-inner-icon="mdiMagnify"
          ></w-text-field>
          <filter-chip
            :p_filter="m_state_filter"
            @update="_refreshProjectList"
          />
        </div>
      </template>
      <template v-slot:body="{ items }">
        <tr
          v-for="project in items"
          :key="project.id"
          class="item_data_table"
        >
          <!-- Reference. -->
          <td class="text-center">
            <p
              v-if="project.orderMaxilla()"
              class="ma-0"
            >
              {{ project.orderMaxilla().getReference() }}
            </p>
            <p
              v-if="project.orderMandible()"
              class="ma-0"
            >
              {{ project.orderMandible().getReference() }}
            </p>
          </td>
          <!-- Installation date. -->
          <td class="text-center">
            <EditableDataTableDatePicker
              :p_disabled="project.status === ProjectState.DELETE"
              :p_data="project.installationDate"
              @update:p_data="project.installationDate = $event"
              @save="updateProject(project)"
              :p_min="m_minDate"
              notempty
            />
          </td>
          <!-- User. -->
          <td class="text-center">
            <EditableDoctor
              :p_disabled="project.status === ProjectState.DELETE"
              :p_patient="project.patient"
              @update:p_patient="project.patient = $event"
              :p_userList="m_userList"
              @save="updatePatient(project.patient)"
            >
            </EditableDoctor>
          </td>
          <!-- Patient. -->
          <td class="text-center">
            <EditablePatient
              :p_disabled="project.status === ProjectState.DELETE"
              :p_project="project"
              @update:p_project="project = $event"
              @save="updateProject(project)"
            >
            </EditablePatient>
          </td>
          <!-- Arcade image. -->
          <td class="text-center">
            <img
              v-if="project.orderMaxilla() && project.orderMandible()"
              src="/img/icon/default/maxilla-mandible.svg"
              alt="Both arcades"
            />
            <img
              v-else-if="project.orderMaxilla()"
              src="/img/icon/default/maxilla.svg"
              alt="Arcade Maxillaire"
            />
            <img
              v-else
              src="/img/icon/default/mandible.svg"
              alt="Arcade Mandibulaire"
            />
          </td>
          <!-- Material. -->
          <td class="text-center">
            <span v-if="project.wireMaxilla()">
              {{ MaterialString[project.wireMaxilla().material] }}</span
            >
            <br v-if="project.wireMaxilla() && project.wireMandible()" />
            <span v-if="project.wireMandible()">
              {{ MaterialString[project.wireMandible().material] }}</span
            >
          </td>

          <!-- Teeth range. -->
          <td class="text-center">
            <span v-if="project.wireMaxilla()">
              <EditableDataTableValue
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.wireMaxilla().teethRange"
                notempty
                @save="updateWire(project.wireMaxilla())"
              />
            </span>
            <span v-if="project.wireMandible()">
              <EditableDataTableValue
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.wireMandible().teethRange"
                notempty
                @save="updateWire(project.wireMandible())"
              />
            </span>
          </td>
          <!--  State. -->
          <td class="text-center">
            <div
              v-if="project.orderMaxilla()"
              class="mb-1"
            >
              <EditableDataTableSelectChip
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.orderMaxilla().status"
                :p_itemvalues="OrderState"
                :p_itemtexts="OrderStateString"
                @save="updateOrder(project.orderMaxilla())"
              />
            </div>

            <div v-if="project.orderMandible()">
              <EditableDataTableSelectChip
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.orderMandible().status"
                :p_itemvalues="OrderState"
                :p_itemtexts="OrderStateString"
                @save="updateOrder(project.orderMandible())"
              />
            </div>
          </td>
          <!-- Key. -->
          <td class="text-center">
            <span v-if="project.wireMaxilla()">
              <EditableDataTableSelect
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.wireMaxilla().keyNeeded"
                :p_itemvalues="Key"
                :p_itemtexts="KeyString"
                @save="updateWire(project.wireMaxilla())"
              />
            </span>
            <span v-if="project.wireMandible()">
              <EditableDataTableSelect
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.wireMandible().keyNeeded"
                :p_itemvalues="Key"
                :p_itemtexts="KeyString"
                @save="updateWire(project.wireMandible())"
              />
            </span>
          </td>
          <!-- 3D print. -->
          <td class="text-center">
            <span v-if="project.orderMaxilla()">
              <w-checkbox
                :disabled="project.status === ProjectState.DELETE"
                v-model="project.orderMaxilla().printed3D"
                @input="updateOrder(project.orderMaxilla())"
                v-ripple
                hide-details
                density="compact"
              ></w-checkbox>
            </span>
            <span v-if="project.orderMandible()">
              <w-checkbox
                :disabled="project.status === ProjectState.DELETE"
                v-model="project.orderMandible().printed3D"
                @input="updateOrder(project.orderMandible())"
                v-ripple
                hide-details
                density="compact"
              ></w-checkbox>
            </span>
          </td>
          <!-- Carrier. -->
          <td class="text-center">
            <span v-if="project.orderMaxilla()">
              <EditableDataTableSelect
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.orderMaxilla().carrier"
                :p_itemvalues="Carrier"
                :p_itemtexts="CarrierString"
                @save="updateOrder(project.orderMaxilla())"
              />
            </span>
            <span v-if="project.orderMandible()">
              <EditableDataTableSelect
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.orderMandible().carrier"
                :p_itemvalues="Carrier"
                :p_itemtexts="CarrierString"
                @save="updateOrder(project.orderMandible())"
              />
            </span>
          </td>
          <!-- Tracking. -->
          <td class="text-center">
            <span v-if="project.orderMaxilla()">
              <EditableDataTableValue
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.orderMaxilla().trackingNumber"
                @save="updateOrder(project.orderMaxilla())"
              />
            </span>
            <span v-if="project.orderMandible()">
              <EditableDataTableValue
                :p_disabled="project.status === ProjectState.DELETE"
                v-model:p_data="project.orderMandible().trackingNumber"
                @save="updateOrder(project.orderMandible())"
              />
            </span>
          </td>
          <!-- Order date. -->
          <td class="text-center">
            <span v-if="project.orderMaxilla()">
              {{ DateHelper.formatDate(project.orderMaxilla().creationDate) }}
            </span>
            <span v-else-if="project.orderMandible()">
              {{ DateHelper.formatDate(project.orderMandible().creationDate) }}
            </span>
          </td>
          <td class="text-center">
            <w-menu
              offset-y
              activator="parent"
              v-model="project.actionsMenu"
              ><template v-slot:activator="{ props }">
                <w-btn
                  class="mr-2"
                  size="small"
                  v-bind="props"
                  variant="text"
                  :icon="mdiDotsVertical"
                >
                </w-btn>
              </template>
              <w-list density="compact">
                <w-list-item
                  v-if="project.status !== ProjectState.DELETE"
                  link
                  :prepend-icon="mdiEye"
                  title="Ouvrir"
                  @click="load(project)"
                >
                </w-list-item>
                <w-list-item
                  v-if="project.status === ProjectState.NEW"
                  link
                  :prepend-icon="mdiDelete"
                  title="Supprimer le projet"
                  @click="deleteProject(project)"
                >
                </w-list-item>
                <w-list-item
                  v-else
                  link
                  :prepend-icon="mdiDeleteRestore"
                  title="Rétablir le projet"
                  @click="restoreProject(project)"
                >
                </w-list-item>
                <w-list-item
                  v-if="project.status !== ProjectState.DELETE"
                  link
                  :prepend-icon="mdiContentDuplicate"
                  title="Dupliquer le projet"
                  @click="duplicateProject(project)"
                >
                </w-list-item>
              </w-list>
            </w-menu>
          </td>
        </tr>
      </template>
    </w-data-table-server>

    <BaseDialog
      :p_no-padding="true"
      ref="deleteDialog"
      p_title="Confirmation"
      p_subtitle="Êtes-vous sûr de vouloir supprimer ce projet ?"
      max-width="600"
    >
      <w-card-actions class="justify-end">
        <w-btn
          variant="text"
          @click="refuse()"
        >
          Non
        </w-btn>
        <w-btn
          variant="text"
          color="red darken-4"
          @click="confirm()"
        >
          Oui
        </w-btn>
      </w-card-actions>
    </BaseDialog>
    <BaseDialog
      :p_no-padding="true"
      ref="restoreDialog"
      p_title="Confirmation"
      p_subtitle="Êtes-vous sûr de vouloir restaurer ce projet ?"
      max-width="600"
    >
      <w-card-actions class="justify-end">
        <w-btn
          variant="text"
          @click="refuse()"
        >
          Non
        </w-btn>
        <w-btn
          variant="text"
          color="red darken-4"
          @click="confirm()"
        >
          Oui
        </w-btn>
      </w-card-actions>
    </BaseDialog>

    <BaseDialog
      :p_no-padding="true"
      ref="dupplicationDialog"
      p_title="Confirmation"
      p_subtitle="Êtes-vous sûr de vouloir duppliquer ce projet ?"
      max-width="600"
    >
      <w-card-actions class="justify-end">
        <w-btn
          variant="text"
          @click="refuse()"
        >
          Non
        </w-btn>
        <w-btn
          variant="text"
          color="red darken-4"
          @click="confirm()"
        >
          Oui
        </w-btn>
      </w-card-actions>
    </BaseDialog>
  </w-container>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import Filterchip from "@/components/dashboard/tables/filterChip/FilterChip.vue";
import FilterValue from "@/components/dashboard/tables/filterChip/FilterValue";

import { AuthHelper } from "@/helpers/AuthHelper";
import DateHelper from "@/helpers/DateHelper";
import OrderHelper from "@/helpers/OrderHelper";
import PatientHelper from "@/helpers/PatientHelper";
import ProjectHelper from "@/helpers/ProjectHelper";
import WinnoveHelper, { DataTableOptions } from "@/helpers/WinnoveHelper";
import WireHelper from "@/helpers/WireHelper";
import Order from "@/models/Order";
import Patient from "@/models/Patient";
import Project from "@/models/Project";
import User from "@/models/User";
import Wire from "@/models/Wire";
import router, { ROUTE_EDITOR } from "@/router";
import {
  AlgoVersion,
  Carrier,
  CarrierString,
  Key,
  KeyString,
  MaterialString,
  OrderState,
  OrderStateString,
  ProjectState,
  Role,
} from "@winnove/vue-wlib/enums";

import Logger from "@/shared/logger";
import {
  mdiDelete,
  mdiEye,
  mdiMagnify,
  mdiArchiveArrowDown,
  mdiDeleteRestore,
  mdiContentDuplicate,
  mdiDotsVertical,
} from "@mdi/js";
import Cookies from "js-cookie";
import { useRepo } from "pinia-orm";
import {
  WritableComputedRef,
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
} from "vue";
import PaginationFilter from "@/components/dashboard/tables/filterChip/PaginationFilter";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [{ key: "creationDate", order: "desc" }],
  groupBy: [],
  search: "",
};

export default defineComponent({
  name: "AdminProjectTable",
  components: {
    BaseDialog,
    Filterchip,
  },
  setup(props, context) {
    const actionsMenu = ref(false);

    const m_state_filter = reactive<PaginationFilter>(
      new PaginationFilter("État", "Projects.status", [
        new FilterValue("Actif", [ProjectState.NEW], true),
        new FilterValue("Supprimé", [ProjectState.DELETE], false),
      ])
    );
    //uncheck archived projects
    const deleteDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const restoreDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const dupplicationDialog = ref<InstanceType<typeof BaseDialog> | null>(
      null
    );
    const m_resolve = ref<((p_value: boolean) => void) | null>(null);
    const m_headers = [
      {
        title: "REFERENCE",
        align: "center",
        sortable: false,
      },
      {
        title: "DATE DE LIVRAISON",
        align: "center",
        value: "installationDate",
        sortable: true,
      },
      {
        title: "PRATICIEN",
        align: "center",
        value: "Users.lastName",
        sortable: true,
      },
      {
        title: "PATIENT",
        align: "center",
        value: "Patients.lastName",
        sortable: true,
      },
      { title: "ARCADE", align: "center", sortable: false, filterable: false },
      {
        title: "MATERIAU",
        align: "center",
        sortable: false,
      },
      {
        title: "NUMEROTATION",
        align: "center",
        sortable: false,
      },
      { title: "ETAT", align: "center", sortable: false, filterable: false },
      {
        title: "CLÉ",
        align: "center",
        sortable: false,
      },
      {
        title: "IMPRESSION 3D",
        align: "center",
        sortable: false,
      },
      {
        title: "TRANSPORTEUR",
        align: "center",
        value: "carrier",
        sortable: false,
      },
      {
        title: "SUIVI",
        align: "center",
        value: "trackingNumber",
        sortable: false,
      },
      {
        title: "DATE DE COMMANDE",
        align: "center",
        value: "creationDate",
        sortable: true,
      },
      {
        title: "ACTIONS",
        align: "center",
        sortable: false,
        value: "actions",
        width: "200px",
      },
    ];

    const m_minDate = ref("");
    const m_projects = ref<Project[]>([]);

    const m_loading = ref(true);
    const m_totalCount = ref(0);
    const m_userList = computed(() => {
      return useRepo(User).all();
    });

    const m_options = ref<DataTableOptions>(
      Cookies.get("AdminDashboardOptionsV3")
        ? JSON.parse(Cookies.get("AdminDashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );

    const m_search: WritableComputedRef<string> = computed({
      get: () => m_options.value.search,
      set: (val: string) => {
        m_options.value.search = val;
        _onOptionsChanged();
      },
    });
    const m_page: WritableComputedRef<number> = computed({
      get: () => m_options.value.page,
      set: (val: number) => {
        m_options.value.page = val;
        _onOptionsChanged();
      },
    });
    const m_itemsPerPage: WritableComputedRef<number> = computed({
      get: () => m_options.value.itemsPerPage,
      set: (val: number) => {
        m_options.value.itemsPerPage = val;
        _onOptionsChanged();
      },
    });
    let _refreshProjectListTimeout: ReturnType<typeof setTimeout> | null = null;
    const m_projects_archived = ref<Project[]>([]); // New ref for archived projects

    onMounted(() => {
      if (AuthHelper.getLoggedUser().role == Role.ACCOUNT_MANAGER) {
        let dayOffset = new Date().getHours() < 12 ? 0 : 1;
        m_minDate.value = DateHelper.getNextBusinessDay(dayOffset);
      }
      _refresh();
    });

    async function _refreshProjectList(): Promise<void> {
      m_loading.value = true;
      if (_refreshProjectListTimeout) clearTimeout(_refreshProjectListTimeout);
      _refreshProjectListTimeout = setTimeout(() => {
        (async () => {
          WinnoveHelper.clearAllWithoutSession();
          // Get all config
          await WinnoveHelper.fetchConfig();
          // Get summary data
          try {
            m_totalCount.value = await WinnoveHelper.getSummaryData(
              m_options.value,
              [m_state_filter as PaginationFilter]
            );
          } catch (e: any) {
            Logger.getInstance().error(e.message);
            WinnoveHelper.clearAllWithoutSession();
            m_options.value = DEFAULT_OPTIONS;
            return;
          } finally {
            _refresh();
            m_loading.value = false;
          }
        })();
      }, 250);
    }

    function _onOptionsChanged(): void {
      // store options in cookies, expires in 24h
      Cookies.set("AdminDashboardOptionsV3", JSON.stringify(m_options.value), {
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      });

      if (m_search.value === null) m_search.value = "";

      _refreshProjectList();
    }

    function _refresh(): void {
      m_projects.value = useRepo(Project)
        .query()
        .withAllRecursive()
        .orderBy("sortId", "asc")
        .get();

      m_projects.value.forEach((project) => (project.actionsMenu = false));
    }

    async function updateOrder(p_order: Order): Promise<void> {
      await OrderHelper.updateOrder(p_order).then(() => {
        _refresh();
        Logger.getInstance().success("Commande modifiée avec succès.");
      });
    }

    function load(p_project: Project): void {
      router.push({
        name: ROUTE_EDITOR,
        params: { reference: p_project.reference },
      });
    }

    async function confirmationDialog(): Promise<boolean> {
      deleteDialog.value?.show();
      return new Promise<boolean>((p_resolve: (p_value: boolean) => void) => {
        m_resolve.value = p_resolve;
      });
    }

    async function restoreConfirmationDialog(): Promise<boolean> {
      restoreDialog.value?.show();
      return new Promise<boolean>((p_resolve: (p_value: boolean) => void) => {
        m_resolve.value = p_resolve;
      });
    }

    async function duplicateConfirmationDialog(): Promise<boolean> {
      dupplicationDialog.value?.show();
      return new Promise<boolean>((p_resolve: (p_value: boolean) => void) => {
        m_resolve.value = p_resolve;
      });
    }

    function confirm(): void {
      if (m_resolve.value) m_resolve.value(true);
    }

    function refuse(): void {
      if (m_resolve.value) m_resolve.value(false);
    }

    async function updateProject(p_project: Project): Promise<void> {
      await ProjectHelper.updateProject(p_project).then(() => {
        _refresh();
        Logger.getInstance().success("Projet modifié avec succès.");
      });
    }
    async function deleteProject(p_project: Project): Promise<void> {
      await confirmationDialog().then(async (p_resolve) => {
        if (p_resolve) {
          try {
            await ProjectHelper.archiveProject(p_project);
          } catch (error) {
            Logger.getInstance().error(
              "Erreur lors de la suppression du projet : " + error.message
            );
          } finally {
            deleteDialog.value?.close();
            _refreshProjectList();
          }
        } else {
          deleteDialog.value?.close();
        }
      });
    }

    async function restoreProject(p_project: Project): Promise<void> {
      await restoreConfirmationDialog().then(async (p_resolve) => {
        if (p_resolve) {
          try {
            await ProjectHelper.restoreProject(p_project);
          } catch (error) {
            Logger.getInstance().error(
              "Erreur lors de la suppression du projet : " + error.message
            );
          } finally {
            restoreDialog.value?.close();
            _refreshProjectList();
          }
        } else {
          restoreDialog.value?.close();
        }
      });
    }

    async function duplicateProject(p_project: Project): Promise<void> {
      await duplicateConfirmationDialog().then(async (p_resolve) => {
        if (p_resolve) {
          await ProjectHelper.duplicateProject(p_project);
          _refreshProjectList();
        }
      });

      dupplicationDialog.value?.close();
    }

    async function updatePatient(p_patient: Patient): Promise<void> {
      await PatientHelper.updatePatient(p_patient).then(() => {
        _refresh();
        Logger.getInstance().success("Patient modifié avec succès.");
      });
    }

    async function updateWire(p_wire: Wire): Promise<void> {
      await WireHelper.updateWire(p_wire, AlgoVersion.DEFAULT).then(() => {
        _refresh();
        Logger.getInstance().success("Fil modifié avec succès.");
      });
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      _onOptionsChanged();
    }

    return {
      _refreshProjectList,
      duplicateConfirmationDialog,
      actionsMenu,
      duplicateProject,
      restoreDialog,
      restoreProject,
      ProjectState,
      m_state_filter,
      m_projects_archived,
      DateHelper,
      OrderState,
      OrderStateString,
      MaterialString,
      Carrier,
      CarrierString,
      Key,
      KeyString,
      m_minDate,
      m_projects,
      m_loading,
      m_totalCount,
      m_options,
      m_search,
      deleteDialog,
      dupplicationDialog,
      m_headers,
      m_page,
      m_itemsPerPage,
      m_userList,
      updateOrder,
      load,
      updateProject,
      deleteProject,
      updatePatient,
      updateWire,
      confirm,
      refuse,
      optionsChanged,
      mdiMagnify,
      mdiDelete,
      mdiArchiveArrowDown,
      mdiDeleteRestore,
      mdiEye,
      mdiContentDuplicate,
      mdiDotsVertical,
    };
  },
});
</script>

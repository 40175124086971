<template>
  <EditableDataTableMenu
    :p_disabled="p_disabled"
    :p_data="DateHelper.formatDate(p_data)"
    @cancel="cancel"
    @save="save"
  >
    <DatePicker
      :p_disabled="p_disabled"
      :p_privilege="Privilege.ADMIN"
      @change="changeDate"
      :p_date="m_data"
      :p_min="p_min"
      :p_max="p_max"
      :notempty="notempty"
      hidedetails
    />
  </EditableDataTableMenu>
</template>

<script lang="ts">
import DatePicker from "@/components/shared/DatePicker.vue";
import DateHelper from "@/helpers/DateHelper";
import { Privilege } from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import { DateTime } from "luxon";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "EditableDataTableDatePicker",
  components: { DatePicker },
  inheritAttrs: false,
  props: {
    p_data: {
      // prop sync
      type: String,
      default: "",
    },
    p_hover: {
      type: Boolean,
      default: false,
    },
    notempty: {
      type: Boolean,
      default: false,
    },
    p_min: {
      type: String,
      default: "",
    },
    p_max: {
      type: String,
      default: "",
    },
    p_disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_data = computed({
      get: () => DateTime.fromISO(props.p_data).toISODate(),
      set: (value: string) => context.emit("update:p_data", value),
    });

    const m_dataSave = ref(props.p_data);

    onMounted(() => {
      m_dataSave.value = m_data.value;
    });

    function cancel(): void {
      m_data.value = m_dataSave.value;
    }

    function changeDate(p_data: string): void {
      const date = DateTime.fromISO(p_data).toUTC().toISO();
      m_data.value = date;
    }

    function save(): void {
      // check if the date is valid
      if (!m_data.value) {
        cancel();
        Logger.getInstance().error("Entrée non valide! ");
        return;
      }
      m_dataSave.value = m_data.value;
      context.emit("save");
    }

    return {
      m_data,
      m_dataSave,
      open,
      cancel,
      save,
      changeDate,
      DateHelper,
      Privilege,
    };
  },
});
</script>

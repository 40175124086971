<template>
  <w-container
    fluid
    class="overflow-y-auto pa-0"
  >
    <w-data-table-server
      class="table_project"
      :headers="m_headers"
      :items="m_projects"
      :items-length="m_totalCount"
      :search="m_search"
      :page="m_page"
      :items-per-page="m_itemsPerPage"
      :sortBy="m_options.sortBy"
      :hover="true"
      item-key="id"
      fixed-header
      @update:options="optionsChanged"
    >
      <template v-slot:top>
        <div
          id="search-bar-container"
          class="d-flex"
        >
          <w-text-field
            class="ma-0 mx-4 mt-2 text-primary"
            v-model="m_search"
            placeholder="Rechercher..."
            single-line
            clearable
            hide-details
            color="primary"
            base-color="primary"
            :prepend-inner-icon="mdiMagnify"
          >
          </w-text-field>
          <filter-btn
            v-if="p_privilege === Privilege.ADMIN"
            class="d-none d-md-flex"
            p_text="Ortho"
            @click="selectOrthoFilters()"
          ></filter-btn>
          <filter-btn
            v-if="p_privilege === Privilege.ADMIN"
            class="d-none d-md-flex"
            p_text="Dessin"
            @click="selectCADFilters()"
          ></filter-btn>
          <filter-btn
            v-if="p_privilege === Privilege.ADMIN"
            class="d-none d-md-flex"
            p_text="Prod"
            @click="selectProdFilters()"
          ></filter-btn>
          <filter-chip
            v-if="p_privilege === Privilege.ADMIN"
            :p_filter="m_privilege_filter"
            @update="onOptionsChanged()"
          />
          <filter-chip
            class="mr-4"
            :p_filter="m_state_filter"
            @update="onOptionsChanged()"
          />
        </div>
      </template>
      <template v-slot:body="{ items }">
        <component
          :is="m_rowComponent"
          v-for="project in items"
          :key="project.id"
          :p_privilege="p_privilege"
          :p_project="project"
          :p_isSelected="p_projectSelectedId === project.id"
          @click="selectProject(project)"
          @updateOrder="updateOrder"
          :style="m_loading ? 'opacity: 0.5;' : ''"
        >
        </component>
      </template>
    </w-data-table-server>
  </w-container>
</template>

<script lang="ts">
import { EnumHelper } from "@/helpers/EnumHelper";

import WinnoveHelper, { DataTableOptions } from "@/helpers/WinnoveHelper";
import Order from "@/models/Order";
import Project from "@/models/Project";
import router from "@/router";
import {
  OrderState,
  Privilege,
  PrivilegeString,
  ProjectState,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";
import Cookies from "js-cookie";
import {
  WritableComputedRef,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { mdiMagnify } from "@mdi/js";
import { useRepo } from "pinia-orm";
import OrderStateChip from "../../shared/OrderStateChip.vue";
import FilterBtn from "./filterChip/FilterBtn.vue";
import FilterChip from "./filterChip/FilterChip.vue";
import FilterValue from "./filterChip/FilterValue";
import PaginationFilter from "./filterChip/PaginationFilter";
import AdminProjectRow from "./row/AdminProjectRow.vue";
import ProjectRow from "./row/ProjectRow.vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [{ key: "creationDate", order: "desc" }],
  groupBy: [],
  search: "",
};

const freemium_headers = [
  {
    title: "ÉTAT",
    sortable: false,
    value: "state",
  },
  {
    title: "PATIENT",
    sortable: true,
    value: "Patients.lastName",
  },
  {
    title: "DATE DE LIVRAISON",
    sortable: true,
    value: "installationDate",
  },
  {
    title: "REF UNIQUE",
    sortable: false,
    value: "ref",
  },
  {
    title: "MÂCHOIRE",
    sortable: false,
  },

  {
    title: "DENTS",
    sortable: false,
  },
  {
    title: "CLÉ",
    sortable: false,
  },
  {
    title: "DATE DE CRÉATION",
    sortable: true,
    value: "creationDate",
  },
];

const inOffice_headers = [
  {
    title: "ÉTAT",
    sortable: false,
    value: "state",
  },
  {
    title: "PATIENT",
    sortable: true,
    value: "Patients.lastName",
  },
  {
    title: "DATE DE POSE",
    sortable: true,
    value: "installationDate",
  },
  {
    title: "REF UNIQUE",
    sortable: false,
    value: "ref",
  },
  {
    title: "MÂCHOIRE",
    sortable: false,
  },

  {
    title: "DENTS",
    sortable: false,
  },
  {
    title: "CLÉ",
    sortable: false,
  },
  {
    title: "DATE DE CRÉATION",
    sortable: true,
    value: "creationDate",
  },
];

// Headers for admin users.
// Matches the columns from AdminProjectRow.
const admin_headers = [
  {
    title: "COMS",
    sortable: false,
  },
  {
    title: "DATE DE LIVRAISON",
    sortable: true,
    value: "installationDate",
  },
  {
    title: "ÉTAT",
    sortable: false,
    value: "state",
  },
  {
    title: "REF UNIQUE",
    sortable: false,
    value: "ref",
  },
  {
    title: "PRATICIEN",
    sortable: true,
    value: "Users.lastName",
  },
  {
    title: "PATIENT",
    sortable: true,
    value: "Patients.lastName",
  },
  {
    title: "MÂCHOIRE",
    sortable: false,
  },
  {
    title: "CLÉ",
    sortable: false,
  },
  {
    title: "I3D",
    sortable: false,
  },
  {
    title: "DENTS",
    sortable: false,
  },
  {
    title: "DATE DE CRÉATION",
    sortable: true,
    value: "creationDate",
  },
];

export default defineComponent({
  name: "ProjectTable",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_projectSelectedId: {
      type: Number,
      required: true,
    },
  },
  components: {
    OrderStateChip,
    AdminProjectRow,
    ProjectRow,
    FilterChip,
    FilterBtn,
  },
  data() {
    return {
      someProjectObject: new Project(), // Remplacez {} par votre objet de projet réel
      isSelected: true, // Remplacez false par la valeur appropriée
    };
  },

  setup(props, context) {
    const m_projects = ref<Project[]>([]);
    const m_loading = ref(true);
    const m_totalCount = ref(0);
    const m_options = ref<DataTableOptions>(
      Cookies.get("dashboardOptionsV3")
        ? JSON.parse(Cookies.get("dashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );
    const m_isMounted = ref(false);
    const m_search: WritableComputedRef<string> = computed({
      get: () => m_options.value.search,
      set: (val: string) => {
        m_options.value.search = val;
        onOptionsChanged();
      },
    });
    const m_page: WritableComputedRef<number> = computed({
      get: () => m_options.value.page,
      set: (val: number) => {
        m_options.value.page = val;
        onOptionsChanged();
      },
    });
    const m_itemsPerPage: WritableComputedRef<number> = computed({
      get: () => m_options.value.itemsPerPage,
      set: (val: number) => {
        m_options.value.itemsPerPage = val;
        onOptionsChanged();
      },
    });

    let _refreshProjectListTimeout: ReturnType<typeof setTimeout> | null = null;

    // Indicates which component should be used for the projects table, according to the current user's privilege.
    const m_rowComponent = computed(() => {
      return props.p_privilege === Privilege.ADMIN
        ? AdminProjectRow
        : ProjectRow;
    });

    // Indicates the correct headers array, according to the current user's privilege.
    const m_headers: object = computed(() => {
      switch (props.p_privilege) {
        case Privilege.ADMIN:
          return admin_headers;
        case Privilege.FREEMIUM:
          return freemium_headers;
        case Privilege.IN_OFFICE:
          return inOffice_headers;
        default:
          return freemium_headers;
      }
    });

    const m_state_filter = reactive<PaginationFilter>(
      new PaginationFilter(
        "État",
        "Orders.status",
        FilterValue.fromEnum(
          EnumHelper.getOrderStateList(props.p_privilege),
          Object.values(OrderState).filter(
            (value) => isNaN(Number(value)) === false
          ) as number[]
        )
      )
    );

    const m_privilege_filter = reactive<PaginationFilter>(
      new PaginationFilter(
        "Profils",
        "Users.privilege",
        FilterValue.fromEnum(
          PrivilegeString,
          Object.values(Privilege).filter(
            (value) => isNaN(Number(value)) === false
          ) as number[]
        )
      )
    );

    onMounted(() => {
      m_state_filter.updateFilterFromCookies();
      if (props.p_privilege === Privilege.ADMIN)
        m_privilege_filter.updateFilterFromCookies();

      refreshProjectList();
      m_isMounted.value = true;
    });

    function refresh(): void {
      const filter: string = router.currentRoute.value.params.filter as string;
      const id: number = parseInt(
        router.currentRoute.value.params.id as string
      );

      if (filter && id) {
        if (filter == "patient") {
          m_projects.value = useRepo(Project)
            .query()
            .where("patientId", id)
            .withAllRecursive()
            .get();
        }
      } else
        m_projects.value = useRepo(Project)
          .query()
          .withAllRecursive()
          .orderBy("sortId", "asc")
          .get();
    }

    async function refreshProjectList(): Promise<void> {
      m_loading.value = true;
      if (_refreshProjectListTimeout) clearTimeout(_refreshProjectListTimeout);
      _refreshProjectListTimeout = setTimeout(() => {
        (async () => {
          WinnoveHelper.clearAllWithoutSession();
          // Get all config
          await WinnoveHelper.fetchConfig();
          // Get summary data
          try {
            m_totalCount.value = await WinnoveHelper.getSummaryData(
              m_options.value,
              [
                m_state_filter as PaginationFilter,
                m_privilege_filter as PaginationFilter,
              ]
            );
          } catch (e: any) {
            Logger.getInstance().error(e.message);
            WinnoveHelper.clearAllWithoutSession();
            m_options.value = DEFAULT_OPTIONS;
            return;
          } finally {
            refresh();
            m_loading.value = false;
          }
        })();
      }, 250);
    }

    function onOptionsChanged(): void {
      // store options in cookies, expires in 24h
      if (m_isMounted.value) {
        Cookies.set("dashboardOptionsV3", JSON.stringify(m_options.value), {
          expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        });
      }

      context.emit("unselect-project");

      refreshProjectList();
    }

    function selectProject(project: Project): void {
      context.emit("select-project", project);
    }

    async function updateOrder(order: Order): Promise<void> {
      await refreshProjectList();
    }

    function selectOrthoFilters(): void {
      m_privilege_filter.uncheckAll();
      m_state_filter.uncheckAll();
      m_privilege_filter.checkByValues([
        Privilege.FREEMIUM,
        Privilege.PREMIUM,
        Privilege.ADMIN,
      ]);
      m_state_filter.checkByValues([
        OrderState.TO_BE_COMPLETED,
        OrderState.BEING_VALIDATED,
        OrderState.READY_TO_BE_ORDERED,
      ]);
      m_privilege_filter.storeValInCookies();
      m_state_filter.storeValInCookies();
      onOptionsChanged();
    }

    function selectCADFilters(): void {
      m_privilege_filter.uncheckAll();
      m_state_filter.uncheckAll();
      m_privilege_filter.checkByValues([
        Privilege.FREEMIUM,
        Privilege.PREMIUM,
        Privilege.ADMIN,
      ]);
      m_state_filter.checkByValues([
        OrderState.ORDERED,
        OrderState.TO_CONFIRM,
        OrderState.VALIDATED_BY_DR,
        OrderState.MODIFIED_BY_DR,
        OrderState.REQUEST_UPDATE,
        OrderState.UPDATE_DONE,
      ]);
      m_privilege_filter.storeValInCookies();
      m_state_filter.storeValInCookies();
      onOptionsChanged();
    }

    function selectProdFilters(): void {
      m_privilege_filter.uncheckAll();
      m_state_filter.uncheckAll();
      m_privilege_filter.checkByValues([
        Privilege.FREEMIUM,
        Privilege.PREMIUM,
        Privilege.ADMIN,
      ]);
      m_state_filter.checkByValues([
        OrderState.TO_MAKE,
        OrderState.ORDERED,
        OrderState.TO_CONFIRM,
        OrderState.KEY_TO_BE_MADE,
        OrderState.BEING_VALIDATED,
        OrderState.VALIDATED_BY_DR,
        OrderState.MODIFIED_BY_DR,
        OrderState.REQUEST_UPDATE,
        OrderState.UPDATE_DONE,
        OrderState.MAKING,
      ]);
      m_privilege_filter.storeValInCookies();
      m_state_filter.storeValInCookies();
      onOptionsChanged();
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      onOptionsChanged();
    }

    function requestUpdateFromPinia(): void {
      refresh();
    }

    return {
      Privilege,
      m_headers,
      m_rowComponent,
      m_projects,
      m_loading,
      m_totalCount,
      m_options,
      m_state_filter,
      m_privilege_filter,
      m_search,
      m_page,
      m_itemsPerPage,
      mdiMagnify,
      refreshProjectList,
      onOptionsChanged,
      selectProject,
      updateOrder,
      selectOrthoFilters,
      selectCADFilters,
      selectProdFilters,
      optionsChanged,
      requestUpdateFromPinia,
    };
  },
});
</script>

<style lang="scss">
.table_project {
  margin: auto;
  max-width: 1800px !important;

  .v-table__wrapper {
    padding: 0 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 175px);

    table {
      margin: auto;
    }
  }

  .v-data-footer {
    margin: auto;
    max-width: 1800px;
  }

  background: transparent !important;

  table {
    border-spacing: 0 15px !important;
    border-collapse: separate;
  }

  tbody tr {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #ddeaf8;
    font-weight: 700;
    color: #7b8595;
  }

  th {
    background-color: rgb(var(--v-theme-background)) !important;
  }

  th,
  td {
    border-bottom: none !important;
    padding: 3px 10px !important;
  }

  td:first-child {
    border-radius: 4px 0 0 4px !important;
  }

  td:last-child {
    border-radius: 0 4px 4px 0 !important;
  }

  tr {
    cursor: pointer;
  }

  .v-data-table-header th {
    white-space: nowrap;
  }
}
</style>

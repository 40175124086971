<template>
  <w-container
    fluid
    class="overflow-y-auto pa-0"
  >
    <div
      class="d-flex flex-column-reverse flex-md-row justify-start justify-md-space-between"
    >
      <div class="my-2 mx-4 d-flex flex-row justify-start">
        <w-btn
          v-if="isInShippingTab()"
          class="mr-2"
          size="small"
          variant="outlined"
          height="24px"
          :prepend-icon="mdiPackageVariantClosed"
          @click="orderShippingDialog.show(m_selectedOrders)"
          :disabled="areAllOrdersReadyToBeShipped() === false"
        >
          Ajouter un numéro de suivi.
        </w-btn>
        <w-btn
          v-if="isInI3DTab()"
          class="mr-2"
          size="small"
          :disabled="m_selectedOrders.length === 0"
          variant="outlined"
          height="24px"
          :prepend-icon="mdiKeyVariant"
          @click="downloadI3DZip(m_selectedOrders)"
        >
          Télécharger les STLs I3D.
        </w-btn>
        <w-btn
          v-if="showGroupByOrtho()"
          data-cy="show-group-by-ortho"
          class="mr-2"
          size="small"
          variant="outlined"
          height="24px"
          :prepend-icon="mdiFormatListGroup"
          @click="togglegroupByOrtho()"
        >
          Grouper par ortho.
        </w-btn>

        <!-- max date -->
        <div
          v-if="isInProduction()"
          class="mx-2"
          style="line-height: 1 !important"
        >
          <w-menu
            v-model="m_dateMenu"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ props }">
              <w-btn
                class="mr-2"
                size="x-small"
                v-bind="props"
                variant="tonal"
                height="24px"
                data-cy="task-date-btn"
              >
                <span class="ml-2 font-weight-bold"
                  >Tâches à faire pour {{ getRelativeMaxDate() }}</span
                >
                <w-icon :icon="mdiChevronDown"></w-icon>
              </w-btn>
            </template>

            <w-date-picker
              data-cy="date-picker-popup"
              v-model="m_selectedDate"
              @update:model-value="changeMaxDate"
              color="white"
              bg-color="primary"
              hide-header
              show-adjacent-months
            ></w-date-picker>
          </w-menu>
        </div>
      </div>

      <div
        class="mt-2"
        id="shipping-search-bar-container"
      >
        <w-text-field
          v-if="isInShippingTab()"
          v-model="m_search"
          placeholder="Rechercher..."
          color="primary"
          class="text-primary"
          base-color="primary"
          single-line
          clearable
          hide-details
          :prepend-inner-icon="mdiMagnify"
        />
      </div>

      <ProductionProjectTableStats
        :p_criticalNumber="m_criticalOrderNumber"
        :p_urgentNumber="m_urgentOrderNumber"
        :p_loading="m_loading"
      ></ProductionProjectTableStats>
    </div>

    <w-data-table-server
      data-cy="production-table"
      class="table_production"
      :headers="m_headers"
      :items="m_ordersItems"
      :items-length="m_totalCount"
      item-key="id"
      :page="m_page"
      :search="m_search"
      :items-per-page="m_itemsPerPage"
      :show-select="showSelect()"
      @update:options="optionsChanged"
    >
      <template
        #header.data-table-select
        v-if="showSelect()"
      >
        <td class="pl-2">
          <w-checkbox
            :modelValue="
              m_selectedOrders.length > 0 &&
              m_selectedOrders.length === m_ordersItems.length
            "
            @update:model-value="selectAll"
            hide-details
            density="compact"
          ></w-checkbox>
        </td>
      </template>
      <template v-slot:body>
        <!-- for every doctor -->
        <template
          v-for="it in getOrders()"
          :key="it.name"
        >
          <tr
            class="tableInfoRow"
            v-if="isGroupByOrtho()"
          >
            <td v-if="showSelect()">
              <w-checkbox
                :modelValue="selectedOrthos(it.name)"
                @update:model-value="selectOrtho(it.name, $event)"
                hide-details
                density="compact"
              ></w-checkbox>
            </td>
            <td
              :colspan="m_headers.length"
              class=""
            >
              <h4>Dr. {{ it.name }}</h4>
            </td>
          </tr>

          <tr
            :class="{
              ProductionProjectRow: true,
              unavailable: !matchProductionStep(order),
              critical: isOrderCritical(order),
              urgent: isOrderUrgent(order) && !isOrderCritical(order),
              normal: !isOrderUrgent(order),
            }"
            data-cy="production-table-row"
            v-for="order in it.items"
            :key="order.id"
          >
            <!-- select -->
            <td v-if="showSelect()">
              <w-checkbox
                :modelValue="isSelected(order)"
                @update:model-value="selectItem(order.id, $event)"
                hide-details
                density="compact"
              ></w-checkbox>
            </td>

            <!-- Assigned to -->
            <td v-if="containsHeaders('ATTRIBUE A')">
              <w-tooltip right>
                <template v-slot:activator="{ props }">
                  <w-avatar
                    color="#d4d3ed"
                    size="32"
                    v-bind="props"
                    >{{ order.productionProcess.user.getInitials() }}</w-avatar
                  >
                </template>
                <span
                  >Attribué à {{ order.productionProcess.user.firstName }}
                  {{ order.productionProcess.user.lastName }}.</span
                >
              </w-tooltip>
            </td>

            <!-- Comments. -->
            <CommentsDialog
              v-if="containsHeaders('COMS')"
              :p_privilege="p_privilege"
              :p_order="order"
            ></CommentsDialog>

            <!-- Installation date. -->
            <td v-if="containsHeaders('DATE DE LIVRAISON')">
              <p class="ma-0">
                {{ order.project.getFormatedInstallationDate() }}
              </p>
            </td>

            <!-- State. -->
            <td v-if="containsHeaders('ÉTAT')">
              <OrderStateChip
                :p_privilege="p_privilege"
                :p_state="order.status"
                :p_isSelected="false"
                :p_disable="!matchProductionStep(order)"
              />
            </td>

            <!-- Date de creation. -->
            <td v-if="containsHeaders('DATE DE CREATION')">
              <p
                v-if="order"
                class="ma-0"
                data-cy="production-table-row-creation-date"
              >
                {{ order.getFormatedCreationDate() }}
              </p>
            </td>

            <!-- Reference. -->
            <td v-if="containsHeaders('REF UNIQUE')">
              <p
                v-if="order"
                class="ma-0"
                data-cy="production-table-row-reference"
              >
                {{ order.getReference() }}
              </p>
            </td>

            <!-- I3D. -->
            <td v-if="containsHeaders('I3D')">
              <div v-if="order && !order.toBeCompleted()">
                <w-checkbox
                  v-model="order.printed3D"
                  @input="updateOrder(order)"
                  v-ripple
                  :color="'#7b8595'"
                  :disabled="!matchProductionStep(order)"
                  hide-details
                  density="compact"
                  data-cy="i3d-checkbox"
                ></w-checkbox>
              </div>
            </td>

            <!-- Arcade image. -->
            <td v-if="containsHeaders('MÂCHOIRE')">
              <span class="d-flex">
                <img
                  :src="
                    order.jaw === Arcade.MAXILLA
                      ? '/img/icon/active/maxilla.svg'
                      : '/img/icon/active/mandible.svg'
                  "
                  alt="Arcade"
                />
              </span>
            </td>

            <!-- Doctor. -->
            <td
              align-self="center"
              v-if="containsHeaders('PRATICIEN')"
            >
              <p class="ma-0">Dr. {{ order.project.getDoctorName() }}</p>
            </td>

            <!-- Patient. -->
            <td
              align-self="center"
              :class="isSelected(order) ? 'white--text' : 'black--text'"
              v-if="containsHeaders('PATIENT')"
            >
              <p class="ma-0">
                {{ order.project.getPatientLastName() }}
              </p>
              <p class="ma-0">
                {{ order.project.getPatientFirstName() }}
              </p>
            </td>

            <!-- actions -->
            <td align-self="right">
              <div class="d-flex flex-row justify-end align-center pr-6">
                <!-- Download 3D printable file -->
                <w-tooltip
                  v-if="isInI3DTab()"
                  left
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="download3Dfile(order)"
                      v-bind="props"
                      :disabled="!matchProductionStep(order)"
                      data-cy="i3d-btn"
                    >
                      <w-icon :icon="mdiPrinter3dNozzleOutline"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Impression 3D</span>
                </w-tooltip>
                <!-- Mark key as made -->
                <w-tooltip
                  v-if="
                    order.keyHasToBeMade() &&
                    (isInMakingTab() || isInShippingTab())
                  "
                  left
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="markKeyAsMade(order)"
                      v-bind="props"
                      data-cy="key-as-make-btn"
                    >
                      <w-icon :icon="mdiKeyVariant"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Marquer la clé fabriquée.</span>
                </w-tooltip>
                <!-- Ship order -->
                <w-tooltip
                  v-if="isInShippingTab() && hasToBeShipped(order)"
                  left
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="orderShippingDialog.show([order])"
                      v-bind="props"
                      :disabled="!matchProductionStep(order)"
                      data-cy="order-shipping-dialog-btn"
                    >
                      <w-icon :icon="mdiPackageVariantClosed"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Ajouter un numéro de suivi</span>
                </w-tooltip>
                <!-- Track order -->
                <w-tooltip
                  v-if="isInDeliveryTab()"
                  left
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="trackOrder(order)"
                      v-bind="props"
                      :disabled="!matchProductionStep(order)"
                    >
                      <w-icon :icon="mdiTruckDeliveryOutline"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Suivre la commande</span>
                </w-tooltip>
                <!-- Require validation -->
                <w-tooltip
                  v-if="
                    isInDrawingTab() &&
                    order.canRequestValidation() &&
                    order.validationNeed
                  "
                  left
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="requestValidation(order)"
                      v-bind="props"
                      :disabled="!matchProductionStep(order)"
                    >
                      <w-icon :icon="mdiEyeCheckOutline"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Demande de validation</span>
                </w-tooltip>
                <!-- Send to prod -->
                <w-tooltip
                  v-if="
                    isInDrawingTab() &&
                    order.canSendToProd() &&
                    (!order.validationNeed || order.wasValidatedByDr())
                  "
                  left
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="sendToProd(order)"
                      v-bind="props"
                      :disabled="!matchProductionStep(order)"
                      data-cy="send-to-prod-btn"
                    >
                      <w-icon :icon="mdiSend"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Envoyer en production</span>
                </w-tooltip>
                <!-- open project -->
                <w-tooltip left>
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="openOrder(order)"
                      @click.middle="openOrder(order, true)"
                      v-bind="props"
                    >
                      <w-icon :icon="mdiArrowCollapseRight"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Ouvrir</span>
                </w-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </w-data-table-server>
    <OrderShippingDialog
      ref="orderShippingDialog"
      @markAsShipped="markAsShipped"
    ></OrderShippingDialog>
  </w-container>
</template>

<script lang="ts">
import ArchHelper from "@/helpers/ArchHelper";
import OrderHelper from "@/helpers/OrderHelper";
import WinnoveHelper, { DataTableOptions } from "@/helpers/WinnoveHelper";
import Order from "@/models/Order";
import {
  AccountManagerStep,
  Arcade,
  OrderState,
  Privilege,
  ProcessStep,
} from "@winnove/vue-wlib/enums";
import Logger from "@/shared/logger";

import router, {
  ROUTE_ACCOUNT_MANAGER_DELIVERY,
  ROUTE_ACCOUNT_MANAGER_VALIDATION,
  ROUTE_EDITOR,
  ROUTE_PRODUCTIONS_DRAW,
  ROUTE_PRODUCTIONS_I3D,
  ROUTE_PRODUCTIONS_SHIP,
  ROUTE_PRODUCTIONS_TOMAKE,
} from "@/router";
import {
  mdiArrowCollapseRight,
  mdiChevronDown,
  mdiEyeCheckOutline,
  mdiFormatListGroup,
  mdiKeyVariant,
  mdiMagnify,
  mdiMessageTextOutline,
  mdiPackageVariantClosed,
  mdiPrinter3dNozzleOutline,
  mdiSend,
  mdiTruckDeliveryOutline,
} from "@mdi/js";

import CookieHelper from "@/helpers/CookieHelper";
import DateHelper from "@/helpers/DateHelper";
import SortHelper from "@/helpers/SortHelper";
import User from "@/models/User";
import { DateTime } from "luxon";
import moment from "moment";
import { useRepo } from "pinia-orm";
import {
  WritableComputedRef,
  computed,
  defineComponent,
  onMounted,
  ref,
} from "vue";
import OrderShippingDialog from "./dialogs/OrderShippingDialog.vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  groupBy: [],
  search: "",
};

export default defineComponent({
  name: "ProductionTable",
  props: {
    p_headers: {
      type: Array as () => any[],
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_cookie: {
      type: String,
      required: true,
    },
    p_route: {
      type: String,
      required: true,
    },
    p_groupByOrtho: {
      type: Boolean,
      required: true,
    },
    p_select: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const m_headers = props.p_headers;
    // dates
    const m_endOfDay = moment.utc().endOf("day").toISOString();
    const m_maxDate = ref(m_endOfDay);
    // stats
    const m_urgentOrderNumber = ref(0);
    const m_criticalOrderNumber = ref(0);
    // selection
    const m_selectedOrders = ref<Order[]>([]);
    const m_selectedOrthos = ref<User[]>([]);
    const m_actionMenu = ref(false);

    const m_ordersItems = ref<Order[]>([]);
    let m_orders: Order[] = [];
    const m_menu = ref(false);
    const m_loading = ref(true);
    const m_dateMenu = ref(false);

    const m_selectedDate: WritableComputedRef<String> = computed({
      get() {
        return new DateTime(m_maxDate.value);
      },
      set(value: DateTime) {
        m_maxDate.value = moment(value.toISO())
          .endOf("day")
          .utc()
          .endOf("day")
          .toISOString();
        CookieHelper.setCookie(
          "maxDateDashboardProd",
          m_maxDate.value,
          new Date(m_endOfDay)
        );
      },
    });

    const m_totalCount = ref(0);
    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie(props.p_cookie)
        ? JSON.parse(CookieHelper.getCookie(props.p_cookie)!)
        : DEFAULT_OPTIONS
    );

    const m_search: WritableComputedRef<string> = computed({
      get: () => m_options.value.search,
      set: (val: string) => {
        m_options.value.search = val;
        onOptionsChanged();
      },
    });

    const m_page: WritableComputedRef<number> = computed({
      get: () => m_options.value.page,
      set: (val: number) => {
        m_options.value.page = val;
        onOptionsChanged();
      },
    });
    const m_itemsPerPage: WritableComputedRef<number> = computed({
      get: () => m_options.value.itemsPerPage,
      set: (val: number) => {
        m_options.value.itemsPerPage = val;
        onOptionsChanged();
      },
    });

    const m_groupByOrtho = ref(props.p_groupByOrtho);

    let _refreshProjectListTimeout: ReturnType<typeof setTimeout> | null = null;

    const orderShippingDialog = ref<InstanceType<
      typeof OrderShippingDialog
    > | null>(null);

    function refresh(): void {
      m_orders = useRepo(Order).query().withAllRecursive().get();
      m_ordersItems.value = useRepo(Order).query().withAllRecursive().get();
    }

    async function refreshProjectList(): Promise<void> {
      m_loading.value = true;
      m_selectedOrthos.value = [];
      m_selectedOrders.value = [];
      if (_refreshProjectListTimeout) clearTimeout(_refreshProjectListTimeout);
      _refreshProjectListTimeout = setTimeout(() => {
        // Get summary data
        (async () => {
          try {
            const step: ProcessStep | AccountManagerStep | undefined =
              getProcessStep();
            if (step !== undefined) {
              const counts: {
                count: number;
                urgent: number;
                critical: number;
              } = await WinnoveHelper.getProductionSummaryData(
                isInProduction() ? "production/" : "accountmanager/",
                step,
                m_options.value,
                isInProduction() ? m_maxDate.value : ""
              );
              m_totalCount.value = counts.count;
              m_criticalOrderNumber.value = counts.critical;
              m_urgentOrderNumber.value = counts.urgent;
            }
          } catch (e: any) {
            Logger.getInstance().error(e.message);
            WinnoveHelper.clearAllWithoutSession();
            return;
          } finally {
            refresh();
            m_loading.value = false;
          }
        })();
      }, 250);
    }

    onMounted(() => {
      const maxDateFromCookie = CookieHelper.getCookie("maxDateDashboardProd");
      if (maxDateFromCookie) {
        m_maxDate.value = maxDateFromCookie;
      }
      refreshProjectList();
    });

    function onOptionsChanged(): void {
      refreshProjectList();
      CookieHelper.setCookie(props.p_cookie, m_options.value);
    }

    async function updateOrder(order: Order): Promise<void> {
      await OrderHelper.updateOrder(order);
      refreshProjectList();
    }

    function openOrder(order: Order, newtab: boolean = false): void {
      if (newtab) {
        window.open(
          router.resolve({
            name: ROUTE_EDITOR,
            params: { reference: order.project!.reference },
          }).href,
          "_blank"
        );
      } else
        router.push({
          name: ROUTE_EDITOR,
          params: { reference: order!.project!.reference },
        });
    }

    function isSelected(p_order: Order): boolean {
      return !!m_selectedOrders?.value.find((order) => order.id === p_order.id);
    }

    function selectItem(orderId: number, selected: boolean): void {
      if (selected) {
        m_selectedOrders.value.push(
          m_ordersItems.value.find((order) => order.id === orderId)!
        );
      } else {
        m_selectedOrders.value = m_selectedOrders.value.filter(
          (order) => order.id !== orderId
        );
      }
      // update orthos selection list : select an ortho if all orders are selected, unselect if not
      updateOrthoSelectionForOneOrder(
        m_ordersItems.value.find((order) => order.id === orderId)! as Order
      );
    }

    function updateOrthoSelectionForOneOrder(order: Order) {
      const ortho = order.project!.patient!.user!;
      if (
        m_ordersItems.value
          .filter(
            (order) =>
              order.project!.patient!.user!.lastName.toUpperCase() ===
              ortho.lastName.toUpperCase()
          )
          .every((order) => m_selectedOrders.value.includes(order))
      ) {
        if (!m_selectedOrthos.value.includes(ortho)) {
          m_selectedOrthos.value.push(ortho);
        }
      } else {
        m_selectedOrthos.value = m_selectedOrthos.value.filter(
          (user) => user.lastName.toUpperCase() !== ortho.lastName.toUpperCase()
        );
      }
    }

    function selectAll(value: boolean): void {
      if (!value) {
        m_selectedOrders.value = [];
        m_selectedOrthos.value = [];
      } else {
        m_selectedOrders.value = m_ordersItems.value;
        m_selectedOrthos.value = useRepo(User).all();
      }
    }

    function selectedOrthos(name: string): boolean {
      return m_selectedOrthos.value.some(
        (ortho) => ortho.lastName.toUpperCase() === name
      );
    }

    function selectOrtho(name: string, selected: boolean): void {
      const users = useRepo(User).all();
      if (selected) {
        m_selectedOrthos.value.push(
          users.find((user) => user.lastName.toUpperCase() === name)!
        );
        // select all orders with this ortho
        m_selectedOrders.value = m_selectedOrders.value.concat(
          m_ordersItems.value.filter(
            (order) =>
              order.project!.patient!.user!.lastName.toUpperCase() === name
          )
        );
      } else {
        m_selectedOrthos.value = m_selectedOrthos.value.filter(
          (user) => user.lastName.toUpperCase() !== name
        );
        // unselect all orders with this ortho
        m_selectedOrders.value = m_selectedOrders.value.filter(
          (order) =>
            order.project!.patient!.user!.lastName.toUpperCase() !== name
        );
      }
    }

    function areAllOrdersReadyToBeShipped(): boolean {
      return m_selectedOrders.value.every(
        (order) => order.status === OrderState.TO_SHIP
      );
    }

    function hasToBeShipped(order: any): boolean {
      return order.status === OrderState.TO_SHIP;
    }

    async function markOneOrderAsShipped(
      trackingNumber: string,
      order: Order,
      refreshAfter = true
    ) {
      order.status = OrderState.SHIPPED;
      order.trackingNumber = trackingNumber;
      await updateOrder(order);
      // unselect the order
      selectItem(order.id!, false);
      if (refreshAfter) refreshProjectList();
    }

    async function markAsShipped(trackingNumber: string, orders: Order[]) {
      orders.forEach((order) => {
        (async () => {
          await markOneOrderAsShipped(trackingNumber, order, false);
        })();
      });
      refreshProjectList();
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      onOptionsChanged();
    }

    function getOrders(): ItemGroup<Order>[] {
      if (isGroupByOrtho()) {
        return groupAndSortOrders();
      } else {
        return sortOrders();
      }
    }

    function groupAndSortOrders(): ItemGroup<Order>[] {
      return SortHelper.groupAndSortOrders(m_orders);
    }

    function sortOrders(): ItemGroup<Order>[] {
      return SortHelper.sortOrdersAsGroup(m_orders);
    }

    async function download3Dfile(p_order: Order) {
      await ArchHelper.download3Dfile(p_order);
    }

    async function downloadI3DZip(p_orders: Order[]) {
      await ArchHelper.downloadI3DZip(p_orders);
    }

    function requestValidation(p_order: Order): void {
      p_order.requestValidation();
      updateOrder(p_order);
    }

    function sendToProd(p_order: Order): void {
      p_order.sendToProd();
      updateOrder(p_order);
    }

    function isInI3DTab(): boolean {
      return props.p_route === ROUTE_PRODUCTIONS_I3D;
    }

    function isInShippingTab(): boolean {
      return props.p_route === ROUTE_PRODUCTIONS_SHIP;
    }

    function isInDrawingTab(): boolean {
      return props.p_route === ROUTE_PRODUCTIONS_DRAW;
    }

    function isInMakingTab(): boolean {
      return props.p_route === ROUTE_PRODUCTIONS_TOMAKE;
    }

    function isInDeliveryTab(): boolean {
      return props.p_route === ROUTE_ACCOUNT_MANAGER_DELIVERY;
    }

    function isInValidationTab(): boolean {
      return props.p_route === ROUTE_ACCOUNT_MANAGER_VALIDATION;
    }

    function isInProduction(): boolean {
      return (
        isInI3DTab() || isInShippingTab() || isInDrawingTab() || isInMakingTab()
      );
    }

    function isInAccountManager(): boolean {
      return isInDeliveryTab() || isInValidationTab();
    }

    function showGroupByOrtho(): boolean {
      return (
        isInAccountManager() ||
        isInShippingTab() ||
        isInDrawingTab() ||
        isInI3DTab()
      );
    }

    function getProcessStep(): ProcessStep | AccountManagerStep | undefined {
      if (isInDrawingTab()) return ProcessStep.TO_DRAW;
      if (isInI3DTab()) return ProcessStep.I3D;
      if (isInMakingTab()) return ProcessStep.TO_MAKE;
      if (isInShippingTab()) return ProcessStep.TO_SHIP;
      if (isInValidationTab()) return AccountManagerStep.IN_VALIDATION;
      if (isInDeliveryTab()) return AccountManagerStep.IN_DELIVERY;
      return undefined;
    }

    async function markKeyAsMade(order: Order): Promise<void> {
      order.markKeyAsBuilt();
      await updateOrder(order);
      await refreshProjectList();
    }

    function isOrderUrgent(order: Order): boolean {
      const installationDay = DateHelper.formatDateISO(
        order.project!.installationDate
      );
      const maxUrgentDay = DateHelper.formatDateISO(
        DateHelper.getNextBusinessDay(1)
      );
      return installationDay <= maxUrgentDay;
    }

    function isOrderCritical(order: Order): boolean {
      const installationDay = DateHelper.formatDateISO(
        order.project!.installationDate
      );
      const criticalDay = DateHelper.formatDateISO(new Date().toISOString());
      return installationDay <= criticalDay;
    }

    function isGroupByOrtho(): boolean {
      return m_groupByOrtho.value;
    }

    function showSelect(): boolean {
      return props.p_select;
    }

    function matchProductionStep(p_order: Order): boolean {
      if (isInProduction()) {
        const step: ProcessStep = getProcessStep() as ProcessStep;
        const steps: ProcessStep[] = p_order.getAvailableProcessStep();
        return steps.includes(step);
      } else return true;
    }

    function containsHeaders(title: string): boolean {
      return m_headers.some((header) => header.title === title);
    }

    function togglegroupByOrtho(): void {
      m_groupByOrtho.value = !m_groupByOrtho.value;
    }

    function changeMaxDate() {
      m_dateMenu.value = false;
      refreshProjectList();
    }

    function getRelativeMaxDate() {
      return "le " + moment.utc(m_maxDate.value).format("DD/MM");
    }

    function trackOrder(p_order: Order | null) {
      if (!p_order) return;
      return OrderHelper.trackOrder(p_order.trackingNumber);
    }

    return {
      Privilege,
      Arcade,
      m_page,
      m_menu,
      m_itemsPerPage,
      m_headers,
      m_loading,
      m_totalCount,
      m_options,
      m_selectedOrders,
      m_actionMenu,
      m_dateMenu,
      m_maxDate,
      m_urgentOrderNumber,
      m_criticalOrderNumber,
      m_selectedDate,
      m_search,
      refreshProjectList,
      updateOrder,
      groupAndSortOrders,
      m_ordersItems,
      orderShippingDialog,
      onOptionsChanged,
      optionsChanged,
      openOrder,
      selectItem,
      selectAll,
      selectedOrthos,
      selectOrtho,
      isSelected,
      mdiArrowCollapseRight,
      mdiMessageTextOutline,
      mdiPackageVariantClosed,
      mdiPrinter3dNozzleOutline,
      mdiFormatListGroup,
      mdiKeyVariant,
      mdiMagnify,
      mdiChevronDown,
      mdiEyeCheckOutline,
      mdiTruckDeliveryOutline,
      mdiSend,
      hasToBeShipped,
      markAsShipped,
      areAllOrdersReadyToBeShipped,
      download3Dfile,
      downloadI3DZip,
      isInI3DTab,
      isInShippingTab,
      isInDrawingTab,
      isInMakingTab,
      markKeyAsMade,
      isGroupByOrtho,
      isOrderUrgent,
      isOrderCritical,
      getOrders,
      showSelect,
      matchProductionStep,
      isInAccountManager,
      togglegroupByOrtho,
      containsHeaders,
      changeMaxDate,
      showGroupByOrtho,
      getRelativeMaxDate,
      isInProduction,
      isInDeliveryTab,
      trackOrder,
      requestValidation,
      sendToProd,
    };
  },
});
</script>

<style lang="scss">
.ProductionProjectRow {
  position: relative;
  font-weight: 700;
}

.tableInfoRow {
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-primary));
  td {
    height: auto !important;
  }
}

.table_production {
  margin: auto;

  .v-data-table__wrapper {
    padding: 0 16px;
    background-color: rgb(var(--v-theme-background));
    overflow-y: scroll;
    max-height: calc(100vh - 64px - 60px - 40px);
    @media screen and (max-width: 960px) {
      max-height: calc(100vh - 56px - 60px - 40px);
    }

    table {
      background-color: white;
      max-width: 1800px !important;
      margin: auto;
    }
  }

  th {
    top: 15px !important;
    background: rgb(var(--v-theme-background)) !important;
  }

  // Blocks to hide items shadows on the sides
  th:first-of-type:before {
    content: "";
    position: absolute;
    left: -10px;
    width: 10px;
    background: rgb(var(--v-theme-background));
    height: 100%;
    top: 0;
  }
  th:last-of-type:before {
    content: "";
    position: absolute;
    right: -10px;
    width: 10px;
    background: rgb(var(--v-theme-background));
    height: 100%;
    top: 0;
  }

  .v-data-footer {
    margin: auto;
    max-width: 1800px;
  }

  .urgent {
    color: black;
    background-color: rgba(var(--v-theme-wGrey));
  }

  .normal {
    color: black;
    background-color: rgba(var(--v-theme-wGrey), 0.2);
  }

  .critical {
    color: black;
    background-color: rgba(var(--v-theme-secondary), 0.5);
  }

  .unavailable {
    color: rgba(black, 0.3);
    :deep(.v-chip) {
      opacity: 0.3;
    }
  }
}

#shipping-search-bar-container {
  width: 200px;
  height: 24px;
}

#shipping-search-bar-container .v-text-field__slot {
  height: 14px;
  & > input {
    height: 14px;
  }
}

#shipping-search-bar-container .v-input {
  height: 24px;
  margin: 0 !important;

  .v-input__control {
    height: 24px;

    .v-field {
      height: 24px;
      padding: 0 6px;

      .v-field__prepend-inner {
        height: 24px;
        font-size: 0.75rem;
      }

      .v-field__clearable {
        height: 24px;
        font-size: 0.75rem;
      }

      .v-field__field {
        height: 24px;

        .v-field__input {
          padding-top: 0;
          padding-bottom: 16px;
          font-size: 0.85rem;
        }
      }
    }
  }
}
</style>
